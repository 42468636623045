import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {StyledFontAwesomeIcon, StyledLink} from "../../components/ListComponents";
import {faArrowRight, faEye} from "@fortawesome/free-solid-svg-icons";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {List} from "../../components/List";

export const ClientsVehicles = ({clientId, change}) => {
    const [vehicles, setVehicles] = useState([]);
    const token = useSelector((store) => store.token);
    const readOnly = ['Partner', 'Księgowy'].includes(useSelector((store) => store.roleName));
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState("");
    const [sortDirection, setSortDirection] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchParam, setSearchParam] = useState("");

    const tableColumns = [
        {name: 'ID', property: 'vehicle', relationProperty: 'id'},
        {name: 'Marka', property: 'vehicle', relationProperty: 'mark'},
        {name: 'Model', property: 'vehicle', relationProperty: 'model'},
        {name: 'Nr rejestracyjny', property: 'vehicle', relationProperty: 'registrationNumber'},
        {name: 'Rok produkcji', property: 'vehicle', relationProperty: 'yearbook'},
        {name: 'Data zakupu', property: 'vehicle', relationProperty: 'purchased_at'},
        {name: 'Cena zakupu', property: 'vehicle', relationProperty: 'secondNet'},
        {name: 'Samochód z NEX1', property: 'vehicle', relationProperty: 'sourceNex1'},
        {name: 'Dostawca finansowania', property: 'vehicle', relationProperty: 'financingProvider'},
        {name: 'Instytucja', property: 'vehicle', relationProperty: 'institution'},
        {name: 'Rodzaj finansowania', property: 'vehicle', relationProperty: 'financingType'},
        {name: 'Aktualna polisa', property: 'vehicle', relationProperty: 'policy'},
        {name: 'Koniec aktualnej polisy', property: 'policy', relationProperty: 'endedAt'},
    ];

    useEffect(() => {
        axios.get(`${apiUrl}/client/${clientId}/vehicle`, {
            params: {
                page: page,
                rowsPerPage: rowsPerPage,
                sort: sort,
                sortDirection: sortDirection,
                search: searchParam
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {vehicles: newVehicles, total}}) => {
            setVehicles(newVehicles);
            setTotal(total);
        });
    }, [clientId, token, page, rowsPerPage, sort, sortDirection, searchParam])

    const callbackFunction = (pageChanged, rowsPerPageChanged, sortChanged, sortDirectionChanged, searchParamChanged) => {
        setPage(pageChanged + 1);
        setRowsPerPage(rowsPerPageChanged);
        setSort(sortChanged);
        setSortDirection(sortDirectionChanged);
        setSearchParam(searchParamChanged);
    }

    const cells = (element) => {
        return (
            <>
                <td>{element.vehicle.id}</td>
                <td>{element.vehicle.mark ?? '-'}</td>
                <td>{element.vehicle.model ?? '-'}</td>
                <td>{element.vehicle.registrationNumber}</td>
                <td>{element.vehicle.yearbook ?? '-'}</td>
                <td>{element.vehicle.purchased_at ?? '-'}</td>
                <td>{element.vehicle.secondNet ?? '-'}</td>
                <td>{element.vehicle.sourceNex1 ? 'Tak' : 'Nie'}</td>
                <td>{element.vehicle.financingProvider ?? '-'}</td>
                <td>{element.vehicle.institution ?? '-'}</td>
                <td>{element.vehicle.financingType ?? '-'}</td>
                <td>{element.vehicle.policy ?? '-'}</td>
                <td>{element.policy.endedAt ?? '-'}</td>
            </>
        );
    }

    const actions = (element) => {
        return (
            <>
                {!readOnly && <StyledLink to={`/pojazdy/${element.vehicle.id}`}>
                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                </StyledLink>}
                {readOnly && <StyledLink to={`/pojazdy/zobacz/${element.vehicle.id}`}>
                    <StyledFontAwesomeIcon size="2x" icon={faEye} fixedWidth/>
                </StyledLink>}
            </>
        )
    }

    return (
        <>
            <List
                tableColumns={tableColumns}
                cells={cells}
                data={vehicles}
                actions={actions}
                readonly={readOnly}
                initialTotal={total}
                parentCallback={callbackFunction}
                padding={'0px'}
            ></List>
        </>
    );
};
