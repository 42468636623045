import React from 'react';
import styled from "styled-components";
import {Field, Form} from "react-final-form";
import {Select} from "./Form";
import {Button} from "./Buttons";
import axios from "axios";
import {apiUrl} from "../views/api";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";

const StatusWrapper = styled.div`
  margin-bottom: 40px;
`;

const StyledForm = styled.form`
   display: flex;
   flex-direction: row;
`;

const SelectWrapper = styled.div`
  margin-right: 20px;
  display: flex;
  select{
    width: 300px;
  }
`;

export const Status = ({todoId,nameField,initialValues,statuses, disabled}) => {
    const token = useSelector((store) => store.token);

    return (
        <StatusWrapper>
            <Form
                onSubmit={values => {
                    axios.post(`${apiUrl}/toDos/${todoId}/status`, values, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }).then(() => {
                        toast.success("Status został zmieniony.");
                    }).catch(() => {
                        toast.error("Coś poszło nie tak.");
                    });
                }}
                initialValues={initialValues}
                render={({handleSubmit}) => (
                    <StyledForm onSubmit={handleSubmit}>
                        <Field
                            name={nameField}
                            render={({input}) => (
                                <SelectWrapper>
                                    <Select {...input} id={input.name} disabled={disabled}>
                                        <option value={null}>Status</option>
                                        {statuses?.map((item,key) => (
                                            <option key={key} value={key}>{item}</option>
                                        ))}
                                    </Select>
                                </SelectWrapper>
                            )}/>

                        {!disabled && (
                            <Button>Zmień status</Button>
                        )}
                    </StyledForm>
                )}
            />
        </StatusWrapper>
    );
}
