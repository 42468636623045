import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useRouteMatch, Redirect} from "react-router-dom";
import axios from "axios";
import {faTrash, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "../../components/Buttons";
import {ClientForm} from "./ClientForm";
import {ClientsVehicles} from "./ClientsVehicles";
import {ClientsInsurancePolicy} from "./ClientsInsurancePolicy";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {apiUrl} from "../api";
import {Modal} from "../../components/Modal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ModalButtonWrapper, P} from "../../components/Modals";
import {VehiclesToSelect} from "./VehiclesToSelect";
import {ClientInfo} from "../../components/ClientInfo";
import {ClientsTasks} from "./ClientsTasks";

const TabWrapper = styled.div`
display: grid;
grid-template-columns: repeat(5, 225px);
grid-gap: 15px;
`;
const H1 = styled.h1`
margin-bottom: 10px;
`;
const FirstTabWrapper = styled.div`
display: flex;
&>div{
margin-top: 25px;
margin-left: 20px;
}
`;
const StyledPanelContentWrapper = styled(PanelContentWrapper)`
    max-height: calc(100vh - 170px);
    margin-top: 10px;
`;

const RemoveButton = styled.button`
    background:none;
    border: none;
    color: ${({theme: {colors: {red}}}) => red};
    font-weight: 600;
`;

const AddButton = styled(RemoveButton)`
    color: green;
`;

const ButtonWrapper = styled.div`
    margin: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button:first-child{
    margin-right: 20px;
    }
`;


export const ClientEdit = () => {
    const match = useRouteMatch()
    const token = useSelector((store) => store.token);
    const [activeTab, setActiveTab] = useState(1);
    const [client, setClient] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [addVehicleModal, setAddVehicleModal] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [change, setChange] = useState(1);

    useEffect(() => {
        axios.get(`${apiUrl}/client/${match.params.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {client: newClient}}) => {
            setClient(newClient);
        });

    }, [token, match, change])

    const addVehicleToClient = (id) => {
        axios.put(`${apiUrl}/vehicle/${id}/client/${match.params.id}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(() => {
            toast.success("Dodano pojazd do klienta.");
        }).catch(e => {
            toast.error("Coś poszło nie tak spróbuj jeszcze raz.");
            console.log(e.response)
        }).finally(()=>{
            setAddVehicleModal(false);
            if(activeTab === 2){
                setChange(change+1);
            }
            else{
                setActiveTab(2);
            }
        });
    }

    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <ToastContainer
                position="bottom-right"
            />
            <ButtonWrapper>
                <AddButton onClick={() => {
                    setAddVehicleModal(true);
                }}>
                    <FontAwesomeIcon icon={faPlus} fixedWidth/>
                    Przypisz pojazd klienta
                </AddButton>

                <RemoveButton onClick={() => {
                    setOpenModal(true)
                }}>
                    <FontAwesomeIcon icon={faTrash} fixedWidth/>
                    Usuń klienta
                </RemoveButton>
            </ButtonWrapper>
            <StyledPanelContentWrapper>
                <H1>Dany klient</H1>
                <TabWrapper>
                    <Button type="button" inactive={activeTab !== 1} onClick={() => {
                        setActiveTab(1)
                    }}>Dane klienta</Button>
                    <Button type="button" inactive={activeTab !== 2} onClick={() => {
                        setActiveTab(2)
                    }}>Lista pojazdów</Button>
                    <Button type="button" inactive={activeTab !== 3} onClick={() => {
                        setActiveTab(3)
                    }}>Lista polis</Button>
                    <Button type="button" inactive={activeTab !== 4} onClick={() => {
                        setActiveTab(4)
                    }}>Lista zadań</Button>
                </TabWrapper>
                {activeTab === 1 && <FirstTabWrapper>
                    <ClientForm
                        onSubmit={formData => {
                            formData.append('_method', 'PUT')
                            return axios.post(`${apiUrl}/client/${match.params.id}`, formData, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                }
                            }).then(() => {
                                setChange(change+1);
                                toast.success("Zaktualizowano klienta.");
                            }).catch(e => {
                                if (e.response.status === 422 && e.response.data.message === 'client_defined') {
                                    toast.error("Klient o podanym NIP już istnieje.");
                                    console.log(e.response);
                                } else {
                                    toast.error("Coś poszło nie tak spróbuj jeszcze raz.");
                                    console.log(e.response);
                                }
                            })
                        }}
                        initialValues={client}
                        clientId={match.params.id}
                    />
                    <div>
                        <p>Liczba pojazdów: {client.vehicleCount}</p> <br/>
                        {/*<p>Wolumen transakcji: </p> <br/>
                        <p>Suma składek: </p> <br/>
                        <p>Wszystkie składki razem: </p> <br/>*/}
                        <p>Liczba aktywnych polis: {client.policiesActiveCount}</p> <br/>

                        {client.relatedClients?.length > 0 && (
                            <p><strong>Powiązani:</strong></p>
                        )}
                        {client.relatedClients?.map((clientSimple) => (
                            <ClientInfo clientSimple={clientSimple}/>
                        ))}
                        {client.belongClients?.length > 0 && (
                            <p><strong>Przypisani:</strong></p>
                        )}
                        {client.belongClients?.map((clientSimple) => (
                            <ClientInfo clientSimple={clientSimple}/>
                        ))}
                    </div>
                </FirstTabWrapper>}

                {activeTab === 2 && <div>
                    <ClientsVehicles clientId={client.id} change={change}/>
                </div>}
                {activeTab === 3 && <div>
                    <ClientsInsurancePolicy clientId={client.id}/>
                </div>}
                {activeTab === 4 && <div>
                    <ClientsTasks clientId={client.id}/>
                </div>}
            </StyledPanelContentWrapper>
            {openModal && <Modal closeModal={() => {
                setOpenModal(false)
            }}>
                <P>Czy na pewno chcesz usunąć klienta?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.delete(`${apiUrl}/client/${match.params.id}`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            setRedirect('/klienci');
                            toast.success("Klient został usunięty.");
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setOpenModal(false)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}

            {addVehicleModal && <Modal closeModal={() => {
                setAddVehicleModal(false)
            }}>
                <P>Lista pojazdów bez przypisanych klientów:</P>

                <div>
                    <VehiclesToSelect addVehicleToClient={addVehicleToClient}/>
                </div>
            </Modal>}
        </>
    );
};
