import React, {useEffect, useRef, useState} from 'react';
import {Field, Form} from "react-final-form";
import {Button} from "../../components/Buttons";
import {faComment, faPaperclip, faPlus, faTrash, faUndo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import axios from "axios";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {File} from "../../components/File";
import {ReviewModal} from "../../components/RevierModal";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";
import {Input, Label} from "../../components/Form";

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 140px;
    right: 0;
    height: 40px;
    background-color: #192B38;
    padding-left: 10px;
    padding-right: 30px;
    color: ${({theme: {colors: {white}}}) => white};
    border: none;
    font-size: 20px;

    span {
        padding-left: 5px;
    }
`;

const StyledBox = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: fixed;
    top: 180px;
    right: 0;
    height: 600px;
    padding: 10px 55px 10px 10px;
    background-color: ${({theme: {colors: {white}}}) => white};
    border: none;
    opacity: ${({isOpen}) => isOpen ? 1 : 0};
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    transition: transform .3s ease-in-out, opacity .3s ease-in-out;
    transform: translateX(${({isOpen}) => isOpen ? 0 : "500px"});
    width: 500px;
`;

const StyledTextarea = styled.textarea`
    resize: none;
    width: 100%;
    border-radius: 20px;
    padding: 10px;
`;

const CommentButtons = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 5px;
`;

const HistoryButtons = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;

    button {
        display: flex;
        height: 20px;
        align-items: center;
        justify-content: center;
    }
`;

const StyledMessagesList = styled.ul`
    list-style: none;
    overflow-y: scroll;
    margin: 10px 0;
    padding: 0 10px;
    height: 500px;

    li {
        margin-bottom: 5px;
        padding: 10px 10px 15px;
        display: block;
        font-size: 1.4rem;
    }

    li.history {
        margin-bottom: 5px;
        padding: 10px 10px 15px;
        display: block;
        font-size: 1.4rem;
        background: ${({theme: {colors: {lightGray}}}) => lightGray};
        border-radius: 10px;
    }

    div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .author {
            font-weight: bold;
        }

        .date {
            font-style: italic;

            span {
                margin-right: 10px;
            }

            svg {
                cursor: pointer;
                margin-left: 5px;
            }
        }
    }
`;

const StyledHistoryMessagesList = styled(StyledMessagesList)`
    width: 800px;
`;

const StyledHistoryModalButtonWrapper = styled(ModalButtonWrapper)`
    width: 800px;
`;

const MessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    div.attachment {
        display: flex;
        flex-direction: column;
        align-items: end;
    }
`;

const StyledForm = styled.form`
    flex-shrink: 0;
`;

const StyledA = styled.a`
    color: black;
    text-decoration: none;
    margin-left: 10px;
    cursor: pointer;
`;

const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: 30px 240px;
    align-items: start;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const Comment = ({toDoId, toDoTab, vehicleId, policyId, archived, history}) => {
    const isPartner = ['Partner'].includes(useSelector((store) => store.roleName));
    const loggedUser = useSelector((store) => Number(store.userId));
    const [modal, setModal] = useState(false);
    const [fileUrl, setFileUrl] = useState(false);
    const [openPanel, setOpenPanel] = useState(false);
    const [comments, setComments] = useState([]);
    const [commentFile, setCommentFile] = useState(null);
    const messagesEndRef = useRef(null)
    const [deleteModal, setDeleteModal] = useState(false);
    const [addToHistoryModal, setAddToHistoryModal] = useState(false);
    const [removeFromHistoryModal, setRemoveFromHistoryModal] = useState(false);
    const [showHistoryModal, setShowHistoryModal] = useState(null);
    const token = useSelector((store) => store.token);

    const scrollToBottom = () => {
        // eslint-disable-next-line no-unused-expressions
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"})
    }

    const loadData = () => {
        axios.get(`${apiUrl}/comments`, {
            params: {
                toDoId: toDoId,
                toDoTab: toDoTab,
                policyId: policyId,
                vehicleId: vehicleId
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(({data: {comments: newMessages}}) => {
            setComments(newMessages);
        }).finally(() => {
            scrollToBottom();
        });
    }

    useEffect(() => {
        if (toDoId !== "dodaj") {
            loadData();
        }

    }, [token, toDoId, archived]);

    useEffect(() => {
        if (deleteModal === false) {
            loadData();
        }
    }, [deleteModal])

    useEffect(() => {
        if (addToHistoryModal === false) {
            loadData();
        }
    }, [addToHistoryModal])

    useEffect(() => {
        if (removeFromHistoryModal === false) {
            loadData();
        }
    }, [removeFromHistoryModal])

    const showDeleteButton = (userId) => {
        return !(isPartner && loggedUser !== userId);
    }


    return (
        <>
            <StyledBox isOpen={openPanel}>
                {history && (
                    <>
                        <HistoryButtons>
                            <Button inactive onClick={() => setShowHistoryModal(comments)}>Pokaż historię</Button>
                        </HistoryButtons>
                    </>
                )}
                <StyledMessagesList>
                    {comments && comments.map(({id, userName, isHistory, userId, content, file, created_at}) => (
                        <li class={isHistory ? "history" : ""}>
                            <div>
                                <div class="author">{userName}</div>
                                <div class="date">
                                    <span>{created_at}</span>
                                    {history && (
                                        <>
                                            <FontAwesomeIcon icon={isHistory ? faUndo : faPlus} color="green" size="1x"
                                                             onClick={(e) => {
                                                                 e.preventDefault();
                                                                 if (isHistory) {
                                                                     setRemoveFromHistoryModal(id);
                                                                 } else {
                                                                     setAddToHistoryModal(id);
                                                                 }
                                                             }}/>
                                        </>
                                    )}

                                    {showDeleteButton(userId) === true && (
                                        <>
                                            <FontAwesomeIcon icon={faTrash} color="red" size="1x"
                                                             onClick={(e) => {
                                                                 e.preventDefault();
                                                                 setDeleteModal(id);
                                                             }}/>
                                        </>
                                    )}

                                </div>
                            </div>
                            <MessageWrapper>
                                <span>{content}</span>

                                {file && (
                                    <div class="attachment">
                                        <StyledA onClick={(e) => {
                                            e.preventDefault();
                                            setModal(true);
                                            setFileUrl(file);
                                        }}
                                                 rel="noopener noreferrer"
                                                 target="_blank">
                                            <FontAwesomeIcon icon={faPaperclip} color="grey"
                                                             size="1x"/>
                                        </StyledA>
                                    </div>
                                )}
                            </MessageWrapper>
                        </li>
                    ))}
                    <div ref={messagesEndRef}/>
                </StyledMessagesList>
                <Form
                    onSubmit={(values, form) => {
                        const formData = new FormData();
                        Object.keys(values).forEach((key) => {
                            let value = values[key] ?? '';
                            if (value === true) {
                                value = 1;
                            } else if (value === false) {
                                value = 0;
                            }
                            formData.append(key, value)
                        });

                        if (commentFile) {
                            formData.append('file', commentFile);
                        }

                        if (toDoId) {
                            formData.append('to_do_id', toDoId);
                        }

                        if (vehicleId) {
                            formData.append('vehicle_id', vehicleId);
                        }

                        if (policyId) {
                            formData.append('policy_id', policyId);
                        }

                        if (toDoTab) {
                            formData.append('toDoTab', toDoTab);
                        }
                        axios.post(`${apiUrl}/comments`, formData, {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }).then(({data: {comments: newMessages}}) => {
                            setComments(newMessages);
                            scrollToBottom();
                            setCommentFile(null);
                            form.reset();
                        }).catch((e) => {
                            if (e.response?.status === 422) {
                                toast.error("Wiadomość musi mieć treść");
                            } else {
                                toast.error('Coś poszło nie tak.');
                            }
                        })
                    }}
                    render={({handleSubmit}) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <Field
                                name='content'
                                render={({input, meta}) => (
                                    <StyledTextarea name="" id="" cols="30" rows="4" {...input}/>
                                )}/>
                            {history && (
                                <>
                                    <Field
                                        name="isHistory"
                                        type="checkbox"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Input {...input}
                                                           id={input.name}
                                                           placeholder=""
                                                    />
                                                    <Label>Element historii</Label>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                </>
                            )}
                            <CommentButtons>
                                <File
                                    name="pathFile"
                                    onFileSelect={(file) => setCommentFile(file)}
                                    initialValue={null} //TODO to check
                                />
                                <Button type="submit" disabled={archived} small smallText>Zapisz</Button>
                            </CommentButtons>
                        </StyledForm>
                    )}
                />
            </StyledBox>
            <StyledButton onClick={() => {
                setOpenPanel(!openPanel)
            }}>
                <FontAwesomeIcon icon={faComment} size="1.5x"></FontAwesomeIcon>
                <span>{comments.length}</span>
            </StyledButton>

            <ReviewModal
                modal={modal}
                fileUrl={fileUrl}
                onClose={() => {
                    setModal(false);
                    setFileUrl(null);
                }}
            ></ReviewModal>

            {deleteModal && <Modal closeModal={() => {
                setDeleteModal(false)
            }}>
                <P>Czy na pewno chcesz usunąć komentarz?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.delete(`${apiUrl}/comments/${deleteModal}`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Komentarz został usunięty.");
                        }).catch((e) => {
                            toast.error(e.message);
                        }).finally(() => {
                            setDeleteModal(false);
                        });
                    }}>TAK</Button>
                    <Button inactive onClick={() => setDeleteModal(false)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}

            {addToHistoryModal && <Modal closeModal={() => {
                setAddToHistoryModal(false)
            }}>
                <P>Czy na pewno chcesz dodać komentarz do historii?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.get(`${apiUrl}/comments/${addToHistoryModal}/toggle`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Komentarz został dodany do historii.");
                        }).catch((e) => {
                            toast.error(e.message);
                        }).finally(() => {
                            setAddToHistoryModal(false);
                        });
                    }}>TAK</Button>
                    <Button inactive onClick={() => setAddToHistoryModal(false)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}

            {removeFromHistoryModal && <Modal closeModal={() => {
                setRemoveFromHistoryModal(false)
            }}>
                <P>Czy na pewno chcesz usunąć komentarz z historii?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.get(`${apiUrl}/comments/${removeFromHistoryModal}/toggle`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Komentarz został usunięty z historii.");
                        }).catch((e) => {
                            toast.error(e.message);
                        }).finally(() => {
                            setRemoveFromHistoryModal(false);
                        });
                    }}>TAK</Button>
                    <Button inactive onClick={() => setRemoveFromHistoryModal(false)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}

            {showHistoryModal && <Modal closeModal={() => {
                setShowHistoryModal(null)
            }}>
                <StyledHistoryMessagesList>
                    {comments && comments
                        .filter(comment => comment.isHistory)
                        .map(({id, userName, isHistory, userId, content, file, created_at}) => (
                            <li>
                                <div>
                                    <div className="author">{userName}</div>
                                    <div className="date">
                                        <span>{created_at}</span>
                                    </div>
                                </div>
                                <MessageWrapper>
                                    <span>{content}</span>

                                    {file && (
                                        <div className="attachment">
                                            <StyledA onClick={(e) => {
                                                e.preventDefault();
                                                setModal(true);
                                                setFileUrl(file);
                                            }}
                                                     rel="noopener noreferrer"
                                                     target="_blank">
                                                <FontAwesomeIcon icon={faPaperclip} color="grey"
                                                                 size="1x"/>
                                            </StyledA>
                                        </div>
                                    )}
                                </MessageWrapper>
                            </li>
                        ))}
                    <div ref={messagesEndRef}/>
                </StyledHistoryMessagesList>

                <StyledHistoryModalButtonWrapper>
                    <Button inactive onClick={() => setShowHistoryModal(null)}>Zamknij</Button>
                </StyledHistoryModalButtonWrapper>
            </Modal>}
        </>
    );
};
