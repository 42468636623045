import React, {useEffect, useState} from 'react';
import {useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import styled from "styled-components";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import axios from "axios";
import {apiUrl} from "../api";
import {InsurancePoliciesForm} from "./InsurancePoliciesForm";

const H1 = styled.h1`
  margin-bottom: 10px;
`;

export const InsurancePoliciesShow = () => {
    const token = useSelector((store) => store.token);
    const match = useRouteMatch();
    const [policy, setPolicy] = useState({});
    const [client, setClient] = useState(null);
    const [vehicle, setVehicle] = useState(null);
    const [toDo, setToDo] = useState(null);
    const [change, setChange] = useState(1);
    const [companies, setCompanies] = useState([]);

    const update = () => {
        setChange(change+1);
    }

    useEffect(() => {
        axios.get(`${apiUrl}/companies/all`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({
                     data: {
                         companies: insuranceCompanies
                     }
                 }) => {
            setCompanies(insuranceCompanies);

            axios.get(`${apiUrl}/policy/${match.params.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({data: {policy: newPolicy, client: newClient, vehicle: newVehicle, toDo: newToDo}}) => {
                setPolicy(newPolicy);
                setClient(newClient);
                setVehicle(newVehicle);
                setToDo(newToDo);
            }).catch(e => {
                console.log(e.response)
            });
        });
    }, [token, match.params.id,change]);

    return (
        <>
            <PanelContentWrapper>
                <H1>Dane polisy</H1>
                <InsurancePoliciesForm
                    id={match.params.id}
                    initialValues={policy}
                    client={client}
                    vehicle={vehicle}
                    toDo={toDo}
                    companies={companies}
                    update={update}
                    disabled={true}
                />
            </PanelContentWrapper>
        </>
    );
};
