import React, {useEffect, useState} from 'react';
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {InputFloating, SelectFloating} from "../../components/Form";
import {Button} from "../../components/Buttons";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {Redirect} from "react-router-dom";
import {nip} from "../../validators/nip";

const StyledForm = styled.form`
max-width: 100%;
width: 460px;
`;

export const CreateUser = () => {
    const [roles, setRoles] = useState([]);
    const [redirect, setRedirect] = useState(null);
    const token = useSelector((store) => store.token);
    const roleName = useSelector((store) => store.roleName);

    useEffect(() => {
        axios.get(`${apiUrl}/role`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {roles: newRoles}}) => {
            setRoles(newRoles);
        });
    }, [token]);

    const validate = (values) => {
        const errors = {}
        if (!values.email) {
            errors.email = 'Uzupełnij login'
        }
        if (!values.name) {
            errors.name = 'Uzupełnij imię i nazwisko'
        }
        if (!values.nip) {
            errors.nip = 'Uzupełnij NIP'
        }
        if (!nip(values.nip)) {
            errors.nip = 'NIP jest niepoprawny'
        }
        if (!values.commission) {
            errors.commission = 'Uzupełnij prowizję'
        }
        if (values.commission && (values.commission < 0 || values.commission >= 100)) {
            errors.commission = 'Prowizja powinna być w przedziale 0-100'
        }
        if (!values.phone) {
            errors.phone = 'Uzupełnij telefon'
        }
        if (!values.role_id) {
            errors.role_id = 'Wybierz rolę'
        }
        if (!values.password) {
            errors.password = 'Uzupełnij hasło'
        }
        if (!values.password_confirmation) {
            errors.password_confirmation = 'Uzupełnij hasło'
        }
        if (values.password !== values.password_confirmation) {
            errors.password_confirmation = 'Hasła są różne'
        }
        return errors;
    }

    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <PanelContentWrapper>
                <h2>Dodaj użytkownika</h2>
                <Form
                    validate={values => validate(values)}
                    onSubmit={async values => {
                        let errors = {}
                        await axios.post(`${apiUrl}/user`, values, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            setRedirect(`/uzytkownicy`)
                        }).catch((error) => {
                            const errorTexts = {
                                email: 'Użytkownik z tym adresem e-mail już istnieje'
                            };
                            if (error.response.data.errors.email) {
                                errors.email = errorTexts['email'];
                            }
                        });

                        return errors;
                    }}
                    render={({handleSubmit}) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <Field
                                name="email"
                                type="email"
                                render={({input, meta}) => (
                                    <InputFloating label="Login" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="name"
                                render={({input, meta}) => (
                                    <InputFloating label="Imię i nazwisko" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="phone"
                                render={({input, meta}) => (
                                    <InputFloating label="Numer telefonu" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="password"
                                type="password"
                                render={({input, meta}) => (
                                    <InputFloating label="Hasło" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="password_confirmation"
                                type="password"
                                render={({input, meta}) => (
                                    <InputFloating label="Powtórz hasło" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="nip"
                                render={({input, meta}) => (
                                    <InputFloating label="NIP" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="commission"
                                type="number"
                                step="0.01"
                                render={({input, meta}) => (
                                    <InputFloating label="Prowizja od ubezpieczenia w %" {...input} {...meta}/>
                                )}
                            />
                            { roleName !== 'Partner' ?
                            <Field
                                name="role_id"
                                render={({input, meta}) => (
                                    <SelectFloating label="Uprawnienia" {...input} {...meta}>
                                        <option value="">Uprawienia</option>
                                        {roles.map(({id, name}) => (
                                            <option value={id}>{name}</option>
                                        ))}
                                    </SelectFloating>
                                )}
                            /> : '' }
                            <Button>Zapisz</Button>
                        </StyledForm>
                    )}
                />
            </PanelContentWrapper>
        </>
    );
};
