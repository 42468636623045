import axios from "axios";
import {apiUrl} from "../views/api";
import moment from "moment";
import {toast} from "react-toastify";

export const downloadFile = (url,token,name) => {
    axios.get(url, {
        responseType: 'blob',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    });
}

export const downloadPackage = (token, type, id) => {
    axios.get(`${apiUrl}/downloadPackage/${type}/${id}`, {
        responseType: 'blob',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${type}_${id}_${moment(new Date()).format('YYYY-MM-DD')}.zip`);
        document.body.appendChild(link);
        link.click();
    }).catch((e) => {
        if (e.response?.status === 404) {
            toast.error("Brak plików do stworzenia archiwum.");
        } else {
            console.log(e);
            toast.error("Coś poszło nie tak.");
        }
    });
}

export const isPdf = (url) => {
    return (url.match(/\.(pdf)$/) != null);
}

export const isImage = (url) => {
    return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
}
