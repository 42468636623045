import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {Link, Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import routes from './menuItems';
import {Settings} from "../Settings";
import {UsersList} from "../Users/UsersList";
import {Dashboard} from "../Dashboard";
import {ClientsList} from "../Clients/ClientsList";
import {ClientCreate} from "../Clients/ClientCreate";
import {ClientEdit} from "../Clients/ClientEdit";
import {VehiclesList} from "../Vehicles/VehiclesList";
import {InsurancePoliciesList} from "../InsurancePolicies/InsurancePoliciesList";
import {CreateUser} from "../Users/CreateUser";
import {EditUser} from "../Users/EditUser";
import {ToDoList} from "../ToDo/ToDoList";
import {EditToDo} from "../ToDo/EditToDo";
import {LOGOUT} from "../../actions";
import {VehicleCreate} from "../Vehicles/VehicleCreate";
import {VehicleEdit} from "../Vehicles/VehicleEdit";
import {VehicleShow} from "../Vehicles/VehicleShow";
import {ClientShow} from "../Clients/ClientShow";
import {InsurancePoliciesEdit} from "../InsurancePolicies/InsurancePoliciesEdit";
import {InsurancePoliciesShow} from "../InsurancePolicies/InsurancePoliciesShow";
import {toast, ToastContainer} from "react-toastify";
import {onMessageListener} from "../../firebase/firebase";
import {Tasks} from "../Tasks/Tasks";
import {Task} from "../Tasks/Task";
import {InsuranceCompaniesList} from "../InsuranceCompanies/InsuranceCompaniesList";
import {WalletList} from "../Wallet/WalletList";
import {Wallet} from "../Wallet/Wallet";
import {CreateInsuranceCompany} from "../InsuranceCompanies/CreateInsuranceCompany";
import {EditInsuranceCompany} from "../InsuranceCompanies/EditInsuranceCompany";
import {InsurancePoliciesCreate} from "../InsurancePolicies/InsurancePoliciesCreate";
import {InsuranceCompaniesShow} from "../InsuranceCompanies/InsuranceCompaniesShow";

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
color: ${({theme: {colors: {red}}}) => red};
transition: transform .5s ease-in-out;
transform: rotate(${({opened}) => opened ? 0 : '180deg'});
`
const StyledMenuWrapper = styled.div`
position: absolute;
top: 0;
height: calc(100vh - 133px);
pointer-events: none;
display: flex;
align-items: flex-start;
transition: transform .3s ease-in-out;
transform: translateX(${({opened}) => opened ? 0 : '-230px'});
z-index: 1;
`;
const StyledMenu = styled.ul`
background: ${({theme: {colors: {lightGray}}}) => lightGray};
width: 230px;
height: 100%;
display: flex;
flex-direction: column;
overflow: scroll;
pointer-events: all;
`;
const StyledMain = styled.main`
position: relative;
height: calc(100vh - 133px);
overflow: hidden;
`;
const StyledButton = styled.button`
background: ${({theme: {colors: {lightGray}}}) => lightGray};
padding: 20px 15px;
border: none;
pointer-events: all;
`;
const StyledLink = styled(Link)`
color: ${({theme: {colors: {black}}}) => black};
text-decoration: none;
margin: 25px 0 0 20px;
display: block;
${({isactive}) => !!isactive && css`
font-weight: 600;
`}
`;
export const PanelRouter = () => {
    const [redirect, setRedirect] = useState(null);
    const [menuOpened, setMenuOpened] = useState(false);
    const [currentMenu, setCurrentMenu] = useState([]);
    const location = useLocation();
    const token = useSelector(({token: t}) => t);
    const roleName = useSelector(({roleName: r}) => r);
    const expiresIn = useSelector(({expiresIn: e}) => e);
    // const role = useSelector(({role: r}) => r);
    const dispatch = useDispatch();


    useEffect(() => {
        onMessageListener().then(payload => {
            toast.info(payload.notification.body);
            console.log(payload);
        }).catch(err => console.log('failed: ', err));
        const interval = setInterval(() => {
            if (!token || expiresIn <= new Date().getTime()) {
                dispatch({type: LOGOUT});
                setRedirect('/');
            }
        },10000)
        if (!token || expiresIn <= new Date().getTime()) {
            dispatch({type: LOGOUT});
            setRedirect('/');
        }

        const currentRoutes = routes[location.pathname.split('/')[1]] || [];

        setCurrentMenu(currentRoutes.filter(route => route.roles.includes(roleName)));
        return () => {
            clearInterval(interval);
        }
    }, [location, token, expiresIn, dispatch, roleName])

    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <ToastContainer
                position="bottom-right"
            />
            <StyledMain>
                {!!currentMenu.length && <StyledMenuWrapper opened={menuOpened}>
                    <StyledMenu>
                        {currentMenu.map(({name, path}) => <li key={name}>
                            <StyledLink onClick={()=>{
                                setMenuOpened(!menuOpened)
                            }} to={path} isactive={location.pathname === path}>{name}</StyledLink>
                        </li>)}
                    </StyledMenu>
                    <StyledButton onClick={() => setMenuOpened(!menuOpened)}>
                        <StyledFontAwesomeIcon opened={menuOpened} icon={faChevronLeft} fixedWidth/>
                    </StyledButton>
                </StyledMenuWrapper>}
                <Switch>
                    <Route path="/ustawienia" exact component={Settings}/>

                    <Route path="/uzytkownicy" exact component={UsersList}/>
                    <Route path="/uzytkownicy/dodaj" exact component={CreateUser}/>
                    <Route path="/uzytkownicy/:id" exact component={EditUser}/>

                    <Route path="/pojazdy" exact component={VehiclesList}/>
                    <Route path="/pojazdy/dodaj" exact component={VehicleCreate}/>
                    <Route path="/pojazdy/:id" exact component={VehicleEdit}/>
                    <Route path="/pojazdy/zobacz/:id" exact component={VehicleShow}/>

                    <Route path="/polisy" exact component={InsurancePoliciesList}/>
                    <Route path="/polisy/dodaj" exact component={InsurancePoliciesCreate}/>
                    <Route path="/polisy/:id" exact component={InsurancePoliciesEdit}/>
                    <Route path="/polisy/zobacz/:id" exact component={InsurancePoliciesShow}/>

                    <Route path="/zadania" exact component={ToDoList}/>
                    <Route path="/zadania/archiwum" exact component={() => (
                        <ToDoList archived/>
                    )}/>
                    <Route path="/zadania/:id" exact component={EditToDo}/>

                    <Route path="/klienci" exact component={ClientsList}/>
                    <Route path="/klienci/dodaj" exact component={ClientCreate}/>
                    <Route path="/klienci/:id" exact component={ClientEdit}/>
                    <Route path="/klienci/zobacz/:id" exact component={ClientShow}/>

                    <Route path="/dashboard" exact component={Dashboard}/>
                    <Route path="/taski/:status" exact component={Tasks}/>
                    <Route path="/taski/zobacz/:id" exact render={(props) => <Task type="toDo" {...props} /> }/>
                    <Route path="/taski/osobiste/zobacz/:id" exact render={(props) => <Task type="personal" {...props} /> }/>

                    <Route path="/firmy" exact component={InsuranceCompaniesList}/>
                    <Route path="/firmy/dodaj" exact component={CreateInsuranceCompany}/>
                    <Route path="/firmy/:id" exact component={EditInsuranceCompany}/>
                    <Route path="/firmy/zobacz/:id" exact component={InsuranceCompaniesShow}/>

                    <Route path="/portfele" exact component={WalletList}/>
                    <Route path="/portfele/:id" exact component={Wallet}/>
                </Switch>
            </StyledMain>
        </>
    );
};
