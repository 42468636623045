import {ReviewModal} from "./RevierModal";
import React, {useState} from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faSave} from "@fortawesome/free-solid-svg-icons";

const StyledA = styled.a`
  color: black;
  text-decoration: none;
  margin-left: 10px;
  cursor: pointer;
`;

export const SimpleFileView = ({label, fileUrl}) => {
    const [modal, setModal] = useState(false);

    return (
        <>
            {fileUrl !== null && fileUrl !== "" && (
                <>
                    <div>
                        {label}:
                        <StyledA onClick={(e) => {
                            e.preventDefault();
                            setModal(true);
                        }}
                                 rel="noopener noreferrer"
                                 target="_blank">
                            <FontAwesomeIcon icon={faEye} color="grey"
                                             size="2x"/>
                        </StyledA>
                        <StyledA href={fileUrl}
                                 rel="noopener noreferrer"
                                 target="_blank">
                            <FontAwesomeIcon icon={faSave} color="grey"
                                             size="2x"/>
                        </StyledA>
                    </div>


                    <ReviewModal
                        modal={modal}
                        fileUrl={fileUrl}
                        onClose={() => {
                            setModal(false);

                        }}
                    ></ReviewModal>
                </>)}
        </>
    );
}