import React, {useEffect, useState} from 'react';
import {useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import styled from "styled-components";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {VehicleForm} from "./VehicleForm";
import axios from "axios";
import {apiUrl} from "../api";
import {toast, ToastContainer} from "react-toastify";
import {createFormData} from "../../helpers/form";

const H1 = styled.h1`
  margin-bottom: 10px;
`;

export const VehicleShow = () => {
    const token = useSelector((store) => store.token);
    const match = useRouteMatch();
    const [vehicle, setVehicle] = useState({});
    const [toDo, setToDo] = useState(null);
    const [isLeader, setIsLeader] = useState(null);

    useEffect(() => {
        axios.get(`${apiUrl}/vehicle/${match.params.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {vehicle: newVehicle, toDo: newToDo, isLeader: isLeaderFlag}}) => {
            setVehicle(newVehicle);
            setToDo(newToDo);
            setIsLeader(isLeaderFlag);
        }).catch(e => {
            console.log(e.response)
        });
    }, [token, match.params.id]);

    return (
        <>
            <ToastContainer
                position="bottom-right"
            />
            <PanelContentWrapper>
                <H1>Dany pojazd</H1>
                <VehicleForm
                    withClient={vehicle ? vehicle.client_id : false}
                    client={vehicle ? vehicle.client : null}
                    toDo={toDo}
                    initialValues={vehicle}
                    vehicleId={match.params.id}
                    disabled={true}
                    isLeader={isLeader}
                    onSubmit={(values, registrationCertificateFile, proformaFile, specificationFile, newRegistrationNumberScanFile, invoiceScanFile, leasingScanFile, protocolFile, globalPhotos, globalFiles, resetFiles) => {
                        const formData = new FormData();
                        Object.keys(values).forEach((key) => {
                            formData.append(key, values[key] ?? '')
                        })
                        formData.append('_method', 'PUT');

                        if(registrationCertificateFile){
                            formData.append('registrationProof', registrationCertificateFile);
                        }
                        if(proformaFile){
                            formData.append('proforma', proformaFile);
                        }
                        if(specificationFile){
                            formData.append('specification', specificationFile);
                        }
                        if (newRegistrationNumberScanFile) {
                            formData.append('newRegistrationNumberScan', newRegistrationNumberScanFile);
                        }
                        if (invoiceScanFile) {
                            formData.append('invoiceScan', invoiceScanFile);
                        }
                        if (leasingScanFile) {
                            formData.append('leasingScan', leasingScanFile);
                        }
                        if (protocolFile) {
                            formData.append('protocol', protocolFile);
                        }
                        createFormData(formData, 'photos', values.photos);
                        if (globalPhotos.length > 0) {
                            globalPhotos.forEach(({photos}) => {
                                photos.forEach((photo) => {
                                    formData.append(`photos[${photo.index}][file]`, photo.file);
                                })
                            });
                        }
                        createFormData(formData, 'files', values.files);
                        if (globalFiles.length > 0) {
                            globalFiles.forEach(({files}) => {
                                files.forEach((file) => {
                                    formData.append(`files[${file.index}][file]`, file.file);
                                })
                            });
                        }
                        axios.post(`${apiUrl}/vehicle/${match.params.id}`, formData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {vehicle: newVehicle}}) => {
                            resetFiles();
                            setVehicle(newVehicle);
                            toast.success("Dane zostały zapisane");
                        }).catch(e => {
                            console.log(e.response);
                            toast.error("Coś poszło nie tak.");
                        });
                    }}
                    onSold={
                        values => {
                            axios.put(`${apiUrl}/vehicle/${match.params.id}`, values, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                }
                            }).then(({data: {vehicle: newVehicle}}) => {
                                setVehicle(newVehicle);
                                toast.success("Dane zostały zapisane");
                            }).catch(e => {
                                toast.error("Coś poszło nie tak.");
                                console.log(e.response);
                            });
                        }
                    }
                />
            </PanelContentWrapper>
        </>
    );
};
