import styled from "styled-components";
import React from "react";
import {InputFloating} from "./Form";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const Table = styled.table`
    width: 100%;
    border-spacing: 0;

    th {
        text-align: left;
    }

    td, th {
        text-align: center;
        padding: 3px 0;
        margin: 0;
    }

    tbody {
        tr {
            td {
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    height: 1px;
                    top: 0;
                    left: 0;
                    right: 0;
                    background: #C4C4C4;
                }
            }
        }

        tr:nth-child(even) {
            background-color: lightgray;
        }
    }
`;

const InputSearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 300px;
  margin: 0 20px 0 auto;
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
position: absolute;
right: 0;
font-size: 2.6rem;
`
export const InputSearch = ({...props}) => {
    return (
        <InputSearchWrapper>
            <InputFloating label=" " name="search" {...props}/>
            <StyledFontAwesomeIcon icon={faSearch} fixedWidth/>
        </InputSearchWrapper>
    )
};
