import {gusApi} from "../models/api/gusApi";
import {toast} from "react-toastify";

export const getDataFromGus = (nip, token, callback) => {
    gusApi.get(nip, token).then((response) => {
        if (response.status === 200) {
            callback(response.data.gus);
            toast.success("Pobrano dane z GUS");
        }
    })
        .catch((e) => {
            if (e.response?.status === 404) {
                toast.error("Nie można pobrać danych z podanego NIP.");
            } else {
                console.log(e);
                toast.error("Coś poszło nie tak.");
            }
        });
}