import styled from 'styled-components';

export const Button = styled.button`
  margin-right: 10px;
  border: none;
  background: ${({theme: {colors: {red, darkGray}}, inactive}) => inactive ? darkGray : red};
  color: ${({theme: {colors: {white}}}) => white};
  padding: ${({small}) => small ? "7px 22px" : "17px 35px"};
  font-size: ${({smallText}) => smallText ? "1.2rem" : "2remx"};
  font-weight: 600;
  cursor: pointer;
`;
