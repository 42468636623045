import React, {useEffect, useState} from 'react';
import {faArrowRight, faTrashAlt, faWallet} from "@fortawesome/free-solid-svg-icons";
import {
    StyledFontAwesomeIcon,
    StyledInlineLink, StyledLink,
} from "../../components/ListComponents";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {Modal} from "../../components/Modal";
import {Button} from "../../components/Buttons";
import {ModalButtonWrapper, P} from "../../components/Modals";
import {toast} from "react-toastify";
import {List} from "../../components/List";
import styled from "styled-components";

const IconsWrapper = styled.div`
    display: flex;
`;

const StyledButton = styled.button`
    border: none;
    background: transparent;
    color: ${({theme: {colors: {red}}}) => red};;
    font-weight: 900;
    font-size: 1.6rem;
    top: 5px;
    right: 5px;
`;

export const UsersList = () => {
    const token = useSelector((store) => store.token);
    const isPartner = useSelector((store) => store.roleName) === 'Partner';
    const isCoOwner = useSelector((store) => store.roleName) === 'Współwłaściciel';
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState("");
    const [sortDirection, setSortDirection] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchParam, setSearchParam] = useState("");
    const [openModal, setOpenModal] = useState(null);

    const tableColumns = [
        {name: 'ID', property: 'id'},
        {name: 'Imię i nazwisko', property: 'name', type: 'sort'},
        {name: 'E-mail', property: 'email', type: 'sort'},
        {name: 'Stan portfela', property: 'wallet', relationProperty: 'value'},
        {name: 'Uprawnienie', property: 'role'},
    ];

    useEffect(() => {
        loadData();
    }, [token, page, rowsPerPage, sort, sortDirection, searchParam])

    useEffect(() => {
        if (openModal === false) {
            loadData();
        }
    }, [openModal])

    const loadData = () => {
        axios.get(`${apiUrl}/user`, {
            params: {
                page: page,
                rowsPerPage: rowsPerPage,
                sort: sort,
                sortDirection: sortDirection,
                search: searchParam
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {users: newUsers, total}}) => {
            setUsers(newUsers);
            setTotal(total);
        });
    }

    const callbackFunction = (pageChanged, rowsPerPageChanged, sortChanged, sortDirectionChanged, searchParamChanged) => {
        setPage(pageChanged + 1);
        setRowsPerPage(rowsPerPageChanged);
        setSort(sortChanged);
        setSortDirection(sortDirectionChanged);
        setSearchParam(searchParamChanged);
    }

    const cells = (element) => {
        return (
            <>
                <td>{element.id}</td>
                <td>{element.name}</td>
                <td>{element.email}</td>
                {
                    (!isPartner || element.me) && <td>{parseFloat(element.wallet.value)?.toFixed(2)} zł
                        <StyledInlineLink to={`/portfele/${element.wallet.id}`}>
                            <StyledFontAwesomeIcon size="2x" icon={faWallet} fixedWidth/></StyledInlineLink></td>
                }
                {
                    isPartner && !element.me && <td>-</td>
                }
                <td>{element.role}</td>
            </>
        );
    }

    const actions = (element) => {
        return (
            <>
                <IconsWrapper>
                    {isCoOwner && <StyledButton onClick={() => {
                        setOpenModal(element.id);
                    }}>
                        <StyledFontAwesomeIcon size="2x"  icon={faTrashAlt} fixedWidth/>
                    </StyledButton>}
                    {isCoOwner && <StyledLink to={`/uzytkownicy/${element.id}`}>
                        <StyledFontAwesomeIcon size="2x"  icon={faArrowRight} fixedWidth/>
                    </StyledLink>}
                </IconsWrapper>
            </>
        )
    }

    return (
        <>
            <List
                tableColumns={tableColumns}
                cells={cells}
                data={users}
                actions={actions}
                readonly={false}
                initialTotal={total}
                parentCallback={callbackFunction}
                search={true}
            ></List>

            {openModal && <Modal closeModal={() => {
                setOpenModal(false)
            }}>
                <P>Czy na pewno chcesz usunąć użytkownika?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.delete(`${apiUrl}/user/${openModal}`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Użytkownik został usunięty.");
                        }).catch((e)=>{
                            if (e.response?.status === 403) {
                                toast.error("Nie możesz usunać siebie.");
                            }
                            else{
                                toast.error(e.message);
                            }
                        }).finally(()=>{
                            setOpenModal(false);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setOpenModal(false)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}
        </>
    );
};
