export default {
    uzytkownicy: [{
        name: 'Lista użytkowników',
        path: '/uzytkownicy',
        roles: ['Administrator', 'Współwłaściciel', 'Księgowy'],
    }, {
        name: 'Dodaj użytkownika',
        path: '/uzytkownicy/dodaj',
        roles: ['Współwłaściciel'],
    },],
    klienci: [{
        name: 'Lista klientów',
        path: '/klienci',
        roles: ['Administrator', 'Partner', 'Współwłaściciel', 'Księgowy', 'Administrator procesów'],
    }, {
        name: 'Dodaj klientów',
        path: '/klienci/dodaj',
        roles: ['Administrator', 'Współwłaściciel', 'Administrator procesów'],
    }],
    pojazdy: [{
        name: 'Lista pojazdów',
        path: '/pojazdy',
        roles: ['Administrator', 'Partner', 'Współwłaściciel', 'Księgowy', 'Administrator procesów'],
    }],
    zadania: [{
        name: 'Zadania',
        path: '/zadania',
        roles: ['Administrator', 'Partner', 'Współwłaściciel', 'Księgowy', 'Administrator procesów'],
    }, {
        name: 'Dodaj zadanie',
        path: '/zadania/dodaj',
        roles: ['Administrator', 'Partner', 'Współwłaściciel', 'Księgowy', 'Administrator procesów'],
    }, {
        name: 'Archiwum',
        path: '/zadania/archiwum',
        roles: ['Administrator', 'Partner', 'Współwłaściciel', 'Księgowy', 'Administrator procesów'],
    }],
    firmy: [{
        name: 'Baza TU',
        path: '/firmy',
        roles: ['Administrator', 'Współwłaściciel', 'Księgowy'],
    }, {
        name: 'Dodaj TU',
        path: '/firmy/dodaj',
        roles: ['Współwłaściciel'],
    }],
    polisy: [{
        name: 'Polisy',
        path: '/polisy',
        roles: ['Administrator', 'Partner', 'Współwłaściciel', 'Administrator procesów'],
    }],
}
