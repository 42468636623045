export const createFormData = (formData, key, data, excludes = []) => {
    if (data === Object(data) || Array.isArray(data)) {
        for (let i in data) {
            createFormData(formData, key + '[' + i + ']', data[i], excludes);
        }
    } else {
        if (!excludes.some(exclude => key.includes(exclude))) {
            if (data === false || data === true) {
                if (data === true) {
                    data = 1;
                } else if (data === false) {
                    data = 0;
                }
                formData.append(key, data);
            } else {
                // eslint-disable-next-line eqeqeq
                if (data == "NaN") {
                    formData.append(key, null);
                } else {
                    formData.append(key, data);
                }
            }
        }
    }
}
