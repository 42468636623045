import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import PanelContentWrapper from "./PanelContentWrapper";

export const StyledFontAwesomeSortIcon = styled(FontAwesomeIcon)`
color: ${({theme: {colors: {red}}}) => red};
font-size: 1rem;
margin-left: 3px;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
color: ${({theme: {colors: {red}}}) => red};
font-size: 2rem;
`;

export const StyledFontAwesomeIconActive = styled(StyledFontAwesomeIcon)`
color: ${({theme: {colors: {green}}}) => green};
`;

export const StyledFontAwesomeIconWarning = styled(StyledFontAwesomeIcon)`
color: ${({theme: {colors: {orange}}}) => orange};
`;

export const StyledFontAwesomeIconWhite = styled(StyledFontAwesomeIcon)`
color: ${({theme: {colors: {white}}}) => white};
`;

export const StyledInlineLink = styled(Link)`
margin: auto 0 auto auto;
cursor: pointer;
`;

export const StyledLink = styled(Link)`
display: block;
cursor: pointer;
`;

export const StyledPanelContentWrapper = styled(PanelContentWrapper)`
max-height: calc(100vh - 270px);
margin-top: 10px;
padding: 20px 30px;
`;

export const ListHeader = styled.th`
cursor: pointer;
`;

export const ListHeaderWithoutSort = styled.th`
cursor: default;
`;