import React, {useState} from 'react';
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {Redirect} from "react-router-dom";
import {InsuranceCompanyForm} from "../../components/InsuranceCompanyForm";

export const CreateInsuranceCompany = () => {
    const [redirect, setRedirect] = useState(null);
    const token = useSelector((store) => store.token);
    const fieldTypes = [
        {
            'key': 'input',
            'value': 'Pole tekstowe',
        },
        {
            'key': 'select',
            'value': 'Pole wyboru',
        },
        {
            'key': 'checkbox',
            'value': 'Checkbox',
        },
        {
            'key': 'number',
            'value': 'Wartość liczbowa',
        },
    ]

    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <PanelContentWrapper>
                <h2>Dodaj firmę</h2>
                <InsuranceCompanyForm
                    fieldTypes={fieldTypes}
                    onSubmit={values => {
                        axios.post(`${apiUrl}/companies`, values, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            setRedirect(`/firmy`)
                        });
                    }}
                    disabled={false}
                    initialValues={{
                        name: '',
                        towingFields: [
                            {
                                title: '',
                                field_type: 'input',
                                description: '',
                                is_required: 0,
                            }
                        ]
                    }}
                />
            </PanelContentWrapper>
        </>
    );
};
