import {Field, Form} from "react-final-form";
import {Input, InputFloating, Label, RoundSimpleButton, Select} from "./Form";
import {Button} from "./Buttons";
import React from "react";
import styled from "styled-components";
import arrayMutators from "final-form-arrays";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FieldArray} from "react-final-form-arrays";
import {useSelector} from "react-redux";

const StyledForm = styled.form`
  max-width: 100%;
  width: 460px;
`;

const TasksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
  margin-right: 50px;
`;

const TasksActionWrapper = styled.div`
  flex-direction: row;
`

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #C4C4C4;
  padding-bottom: 15px;
  margin: 10px 0;
`;
const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 450px;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledTextarea = styled.textarea`
  resize: none;
  width: 100%;
  border-radius: 20px;
  padding: 10px;
  border: none;
`;

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledDivError = styled.div`
  color: red;
`;

const LabelWithCheckboxLeft = styled(Label)`
  display: flex;
  span{
    margin-right: 5px;
  }
  input{
    width: auto;
    margin-left: 10px;
}
`

const InputWrapperWithoutLabel = styled(InputWrapper)`
grid-template-columns: 480px;
align-items: center;
`;

export const InsuranceCompanyForm = ({onSubmit, fieldTypes, initialValues, disabled}) => {
    const fullAccess = ['Współwłaściciel'].includes(useSelector((store) => store.roleName));


    const validate = (formsData) => {
        const errors = {towingFields: []}
        if (!formsData.name) {
            errors.name = 'Uzupełnij nazwę'
        }
        if (!formsData.commission) {
            errors.commission = 'Uzupełnij prowizję'
        }
        if (formsData.commission && (formsData.commission < 0 || formsData.commission >= 100)) {
            errors.commission = 'Prowizja powinna być w przedziale 0-100'
        }
        formsData.towingFields && formsData.towingFields.forEach((values, key) => {
            errors.towingFields[key] = {}
            if (!values.title) {
                errors.towingFields[key].title = 'Uzupełnij tytuł'
            }
            if (values.field_type === 'select' && (!values.options || values.options.length === 0)) {
                errors.towingFields[key].field_type = 'Dodaj przynajmniej jedną opcję'
            }
            if (values.options) {
                values.options.forEach((field, index) => {
                    if (!Object.values(field)[0]) {
                        errors.towingFields[key].field_type = 'Uzupełnij wartość opcji'
                    }
                })
            }
        });
        return errors;
    }

    const removeField = (fields, index) => {
        fields.remove(index);
    }

    return (
        <Form
            validate={values => validate(values)}
            onSubmit={onSubmit}
            mutators={{
                // potentially other mutators could be merged here
                ...arrayMutators
            }}
            initialValues={initialValues}
            render={({handleSubmit, form, values}) => (
                <StyledForm onSubmit={handleSubmit}>
                    <fieldset disabled={disabled} style={{display: "contents"}}>
                        <Field
                            name="name"
                            type="text"
                            render={({input, meta}) => (
                                <InputFloating label="Nazwa" {...input} {...meta}/>
                            )}
                        />
                        <Field
                            name="commission"
                            type="number"
                            precision="0.01"
                            render={({input, meta}) => (
                                <InputFloating label="Prowizja w %" {...input} {...meta}/>
                            )}
                        />
                        <FieldsWrapper>
                            <FieldArray name="towingFields">
                                {({fields}) => (
                                    <TasksWrapper>
                                        {(fullAccess && !disabled) && <TasksActionWrapper>
                                            <RoundSimpleButton
                                                type="button"
                                                onClick={() => fields.push({field_type: 'input'})}>
                                                <FontAwesomeIcon icon={faPlus} color="white"/>
                                            </RoundSimpleButton>
                                            <RoundSimpleButton
                                                type="button"
                                                onClick={() => fields.pop()}>
                                                <FontAwesomeIcon icon={faMinus} color="white"/>
                                            </RoundSimpleButton>
                                        </TasksActionWrapper>}

                                        {fields.map((name, index) => (
                                                <Grid key={index}>
                                                    <Field
                                                        name={`${name}.title`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Tytuł</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.description`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Opis</Label>
                                                                    <StyledTextarea name="" id="" cols="30"
                                                                                    rows="4" {...input}/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.field_type`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Typ</Label>
                                                                    <Select {...input} id={input.name} placeholder="">
                                                                        {fieldTypes.map((item) => (
                                                                            <option value={item.key}>{item.value}</option>
                                                                        ))}
                                                                    </Select>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    {values.towingFields[index].field_type === 'select' &&
                                                        <FieldArray name={`${name}.options`}>
                                                            {({fields}) => (
                                                                <div>
                                                                    <TasksActionWrapper>
                                                                        <RoundSimpleButton
                                                                            type="button"
                                                                            onClick={() => fields.push({})}>
                                                                            <FontAwesomeIcon icon={faPlus} color="white"/>
                                                                        </RoundSimpleButton>
                                                                        <RoundSimpleButton
                                                                            type="button"
                                                                            onClick={() => fields.pop()}>
                                                                            <FontAwesomeIcon icon={faMinus} color="white"/>
                                                                        </RoundSimpleButton>
                                                                    </TasksActionWrapper>

                                                                    {fields.map((name, index) => (
                                                                            <Grid key={index}>
                                                                                <Field
                                                                                    name={`${name}.${Object.keys(fields.value[index])[0]}`}
                                                                                    render={({input, meta}) => (
                                                                                        <>
                                                                                            <InputWrapper>
                                                                                                <Label
                                                                                                    htmlFor={input.name}>Opcja {index + 1}</Label>
                                                                                                <Input {...input}
                                                                                                       id={input.name}
                                                                                                       placeholder=""/>
                                                                                                {meta.error && meta.touched &&
                                                                                                    <StyledDivError>{meta.error}</StyledDivError>}
                                                                                            </InputWrapper>
                                                                                        </>
                                                                                    )}/>
                                                                            </Grid>
                                                                        )
                                                                    )}
                                                                </div>
                                                            )}
                                                        </FieldArray>
                                                    }
                                                    <Field
                                                        name={`${name}.is_required`}
                                                        type="checkbox"
                                                        render={({input, meta}) => (
                                                            <InputWrapperWithoutLabel>
                                                                <LabelWithCheckboxLeft>
                                                                    <span>Pole wymagane</span>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </LabelWithCheckboxLeft>
                                                            </InputWrapperWithoutLabel>
                                                        )}/>
                                                    {(fullAccess && !disabled) && <Button type="button" inactive small smallText
                                                                           onClick={() => removeField(fields, index)}>Usuń
                                                        pole</Button>}
                                                </Grid>
                                            )
                                        )}

                                    </TasksWrapper>
                                )}
                            </FieldArray>
                        </FieldsWrapper>
                        {(fullAccess && !disabled) && <Button>Zapisz</Button>}
                    </fieldset>
                </StyledForm>
            )}
        />
    );
}
