import {useSelector} from "react-redux";
import {Field, Form} from "react-final-form";
import axios from "axios";
import {toast} from "react-toastify";
import {Input, Label, Select} from "./Form";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {apiUrl} from "../views/api";
import {Button} from "./Buttons";
import {File} from "./File";
import {createFormData} from "../helpers/form";
import arrayMutators from "final-form-arrays";
import {Files} from "./Files";

const Grid = styled.div`
  padding: 10px;
  background-color: #F0F0F0;
  margin: 8px;
`
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 160px 240px;
  align-items: center;
  margin-bottom: 10px;
  justify-content: flex-start;
`;

const StyledButton = styled(Button)`
  width: 25%;
`;

export const MissingTaskForm = ({toDoId, description, update, closeModal}) => {
    const token = useSelector((store) => store.token);
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState(null);
    const [globalFiles, setGlobalFiles] = useState([]);

    useEffect(() => {
        axios.get(`${apiUrl}/personal/tasks`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {users: newUsers, user_id: newUserId}}) => {
            setUsers(newUsers);
            setUserId(newUserId);
        })
    }, [token])

    const setFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        if (!globalFiles) {
            return;
        }
        if (globalFiles.some(e => e.index === index)) {
            let newArr = [...globalFiles].map((item) => {
                if (item.index === index) {
                    return obj;
                }
                return item;
            });
            setGlobalFiles(newArr);
        } else {
            setGlobalFiles(prevState => [...prevState, obj]);
        }
    }

    return (
        <>
            <Grid>
                <Form
                    initialValues={{user_id: userId, description: description}}
                    onSubmit={values => {
                        const formData = new FormData();
                        Object.keys(values).forEach((key) => {
                            formData.append(key, values[key] ?? '')
                        })
                        createFormData(formData, 'files', values.files);
                        if (globalFiles.length > 0) {
                            globalFiles.forEach(({files}) => {
                                files.forEach((file) => {
                                    formData.append(`files[${file.index}][file]`, file.file);
                                })
                            });
                        }
                        axios.post(`${apiUrl}/tasks/${toDoId}`, formData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Dane zostały zapisane.");
                            update();
                            closeModal(null);
                        }).catch(() => {
                            toast.error("Uzupełnij dane");
                        });
                    }}
                    mutators={{
                        setValue: ([field, value], state, {changeValue}) => {
                            changeValue(state, field, () => value)
                        },
                        ...arrayMutators
                    }}
                    render={({handleSubmit}) => (
                        <StyledForm onSubmit={handleSubmit}>

                            <Field
                                name='description'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Opis</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>

                            <Field
                                name='date'
                                type="date"
                                parse={value => (value === "" ? null : value)}
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Data zadania</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Field
                                name="user_id"
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Użytkownik</Label>
                                            <Select {...input} id={input.name}>
                                                <option value="">Użytkownik</option>
                                                {users.map((item) => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))}
                                            </Select>
                                        </InputWrapper>
                                    </>
                                )}/>
                            <Files name={`files`} index={0} setFilesToForm={setFilesToForm} label={`Załączniki`}/>
                            <StyledButton>Zapisz</StyledButton>
                        </StyledForm>
                    )}
                />
            </Grid>
        </>
    );
}
