import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {
    Input,
    Label,
    ListAddButton,
    ListRemoveButton,
    Select,
    Switch,
    TopFormWrapper
} from "../../components/Form";
import {Field, Form} from "react-final-form";
import {Button} from "../../components/Buttons";
import axios from "axios";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FieldArray} from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {Redirect} from "react-router-dom";
import {Logs} from "../../components/Logs";
import {MissingTask} from "../../components/MissingTask";
import {MissingTaskForm} from "../../components/MissingTaskForm";
import {File} from "../../components/File";
import {createFormData} from "../../helpers/form";

const TasksWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const TasksActionWrapper = styled.div`
    flex-direction: row;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 480px repeat(3, 240px);
    grid-gap: 10px;
    width: 100%;
    border-bottom: 1px solid #C4C4C4;
    padding-bottom: 15px;
    margin: 10px 0;
    align-items: baseline;
`;
const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: 240px 240px;
    align-items: center;
    margin-bottom: 10px;
`;
const BottomWrapper = styled.div`
    margin-top: 20px;

    h4, p {
        padding: 10px 0;
    }

    p {
        color: ${({theme: {colors: {red}}}) => red};
    }
`;

const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledLabel = styled(Label)`
    width: 160px;
    margin: 0.75rem 0;
    display: inline-block;
`;

const DisabledButton = styled(Button)`
    cursor: not-allowed;
`

const LabelWrapper = styled.div`
    h2 {
        display: inline-block;
    }

    button {
        display: inline-block;
        vertical-align: top;
        margin: 4px 0px 0px 10px;
    }
`;

export const ArchiveTab = ({toDoId, archived, archiveRequestSent}) => {
    const [isEnabled, setIsEnabled] = useState(archived);
    const [isRenew, setIsRenew] = useState(archived);
    const [isArchiveRequestSent, setIsArchiveRequestSent] = useState(archiveRequestSent);
    const [users, setUsers] = useState([]);
    const [protocolFile, setProtocolFile] = useState([]);
    const [protocol, setProtocol] = useState([]);
    const [clientName, setClientName] = useState(null);
    const [client, setClient] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [finances, setFinances] = useState([]);
    const [registration, setRegistration] = useState([]);
    const [policy, setPolicy] = useState([]);
    const [archiveStatus, setArchiveStatus] = useState([]);
    const [gap, setGap] = useState([]);
    const [archiveModal, setArchiveModal] = useState(false);
    const [closeTaskModal, setCloseTaskModal] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [redirect, setRedirect] = useState(null);
    const [modal, setModal] = useState(null);
    const [change, setChange] = useState(1);
    const [fees, setFees] = useState(1);
    const [feesSum, setFeesSum] = useState(1);
    const token = useSelector((store) => store.token);
    const readOnly = ['Partner', 'Księgowy', 'Administrator'].includes(useSelector((store) => store.roleName));

    const updateFeesSum = (values) => {
        let sum = parseFloat(fees.insuranceFee);

        if (values?.fee_gap) {
            sum += parseFloat(values?.fee_gap);
        }

        if (values?.fee_leasing) {
            sum += parseFloat(values?.fee_leasing);
        }

        if (values?.fee_car_sale) {
            sum += parseFloat(values?.fee_car_sale);
        }

        setFeesSum(sum);
    }

    const sendArchiveRequest = () => {
        axios.put(`${apiUrl}/toDo/${toDoId}/archive/request`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(() => {
            toast.success("Prośba została wysłana.");
            setIsArchiveRequestSent(true);
        }).catch(() => {
            toast.error("Coś poszło nie tak.");
        });
    }

    const closeTask = () => {
        axios.delete(`${apiUrl}/toDo/${toDoId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(() => {
            toast.success("Zadanie zostało usunięte.");
        }).catch((e) => {
            toast.error("Nie możesz usunać zadania.");
        }).finally(() => {
            setCloseTaskModal(false);
            setRedirect("/zadania");
        });
    }

    useEffect(() => {
        axios.get(`${apiUrl}/toDos/${toDoId}/users`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {users: newUsers}}) => {
            setUsers(newUsers);
        })
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/toDo/${toDoId}/archive/fields`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({
                         data: {
                             archiveStatus,
                             fields,
                             clientFullName,
                             tasks: newTasks,
                             fees: newFees,
                             isDuplicated,
                             protocol
                         }
                     }) => {
                setArchiveStatus(archiveStatus);
                setIsRenew(isDuplicated);
                setClientName(clientFullName);
                setFees(newFees);
                setProtocol(protocol);
                setFeesSum(newFees.insuranceFee + newFees.leasingFee)
                if (fields.client) {
                    setClient(fields.client);
                }
                if (fields.vehicles) {
                    setVehicles(fields.vehicles);
                }
                if (fields.finances) {
                    setFinances(fields.finances);
                }
                if (fields.registration) {
                    setRegistration(fields.registration);
                }
                if (fields.policy) {
                    setPolicy(fields.policy);
                }
                if (fields.gap) {
                    setGap(fields.gap);
                }
                setTasks(newTasks);
            });
        }
    }, [token, toDoId, change]);

    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            {!readOnly && <Switch checked={isEnabled} onChange={() => (setIsEnabled(!isEnabled))}/>}
            <TopFormWrapper>
                <div>
                    <h2>Archiwizacja</h2>
                    {readOnly && !isArchiveRequestSent && <Button inactive disabled={archived} onClick={() => {
                        sendArchiveRequest();
                    }}>Zgłoś archiwizację</Button>}
                </div>
                <div>
                    {clientName && (
                        clientName.map((item, key) => (
                            <h2 key={key}>Dla {item}</h2>
                        ))
                    )}
                </div>
            </TopFormWrapper>

            <Logs
                todoId={toDoId}
            />

            {isEnabled && <>
                <Form
                    onSubmit={values => {
                        const formData = new FormData();
                        Object.keys(values).forEach((key) => {
                            if (key === 'tasks') {
                                createFormData(formData, 'tasks', values.tasks, ['change_status_at']);
                            } else {
                                formData.append(key, values[key] ?? '')
                            }
                        });

                        if (protocolFile) {
                            formData.append('protocol', protocolFile);
                        }

                        axios.post(`${apiUrl}/toDo/${toDoId}/archive`, formData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Twoje dane zostały zapisane.");
                            setRedirect(`/zadania/archiwum`)
                        }).catch(() => {
                            toast.error("Coś poszło nie tak.");
                        });
                    }
                    }

                    mutators={{
                        // potentially other mutators could be merged here
                        ...arrayMutators
                    }}
                    initialValues={{tasks: tasks, protocolDate: protocol?.protocolDate}}
                    render={({handleSubmit, values}) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <h2 style={{marginBottom: "1rem"}}>Protokół zdawczo-odbiorczy</h2>
                            </div>
                            <Field
                                name={`protocolDate`}
                                type="date"
                                parse={value => (value === "" ? null : value)}
                                render={({input, meta}) => (
                                    <>
                                        <div>
                                            <StyledLabel>Data</StyledLabel>
                                            <Input
                                                {...input}
                                                id={input.name}
                                                placeholder=""
                                                onChange={(e) => {
                                                    input.onChange(e);
                                                }}
                                            />
                                        </div>
                                    </>
                                )}/>
                            <File
                                name="protocol_path"
                                label="Plik"
                                onFileSelect={(file) => setProtocolFile(file)}
                                initialValue={protocol?.protocol}
                            />
                            <div>
                                <h2 style={{marginBottom: "1rem"}}>Prowizje</h2>
                            </div>
                            <StyledLabel>Zysk z ubezpieczenia: </StyledLabel><span
                            id="insuranceFee">{fees?.insuranceFee ? fees?.insuranceFee + ' zł' : '-'}</span>
                            <Field
                                name='fee_gap'
                                type="number"
                                render={({input, meta}) => (
                                    <>
                                        <div>
                                            <StyledLabel>Zysk z ubezpieczenia GAP: </StyledLabel>
                                            <Input {...input} placeholder="" onKeyUp={(e) => {
                                                input.onChange(e);
                                                updateFeesSum(values);
                                            }}/>
                                        </div>
                                    </>
                                )}/>
                            <Field
                                name='fee_leasing'
                                initialValue={fees?.leasingFee}
                                type="number"
                                render={({input, meta}) => (
                                    <>
                                        <div>
                                            <StyledLabel>Zysk z leasingu: </StyledLabel>
                                            <Input {...input} placeholder="" onKeyUp={(e) => {
                                                input.onChange(e);
                                                updateFeesSum(values);
                                            }}/>
                                        </div>
                                    </>
                                )}/>
                            <Field
                                name='fee_car_sale'
                                type="number"
                                render={({input, meta}) => (
                                    <>
                                        <div>
                                            <StyledLabel>Zysk ze sprzedaży auta: </StyledLabel>
                                            <Input {...input} placeholder="" onKeyUp={(e) => {
                                                input.onChange(e);
                                                updateFeesSum(values);
                                            }}/>
                                        </div>
                                    </>
                                )}/>
                            <div style={{marginBottom: "2rem"}}><StyledLabel><strong>Suma: </strong></StyledLabel><span
                                id="feeSum">{feesSum ? feesSum + ' zł' : '-'}</span></div>
                            <FieldArray name="tasks">
                                {({fields}) => (
                                    <TasksWrapper>
                                        <LabelWrapper>
                                            <h2>Zadania</h2>
                                            {!archived && (<ListAddButton type="button" onClick={() => fields.push({})}>
                                                <FontAwesomeIcon icon={faPlus} color="white"/> Dodaj
                                            </ListAddButton>)}
                                        </LabelWrapper>
                                        {fields.map((name, index) => (
                                                <Grid key={index}>
                                                    <Field
                                                        name={`${name}.description`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Zadanie inne</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.date`}
                                                        type="date"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.user_id`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value="">Użytkownik</option>
                                                                        {users.map(({name, id: userId}) => (
                                                                            <option value={userId}>{name}</option>
                                                                        ))}
                                                                    </Select>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    {values.tasks[index].change_status_at && (
                                                        <span>Zakończone {values.tasks[index].change_status_at}</span>
                                                    )}
                                                    {!archived && (<div>
                                                        <ListRemoveButton type="button"
                                                                          onClick={() => fields.remove(index)}><FontAwesomeIcon
                                                            icon={faMinus} color="white"/> Usuń</ListRemoveButton>
                                                    </div>)}
                                                </Grid>
                                            )
                                        )}
                                    </TasksWrapper>
                                )}
                            </FieldArray>
                            <BottomWrapper>
                                {protocolFile.length > 0 && (
                                    <>
                                        <h4>Protokół zdawczo-odbiorcy</h4>
                                        <ErrorWrapper>
                                            {protocolFile.map((item) => (
                                                <MissingTask
                                                    item={item}
                                                    closure={() => {
                                                        setModal(`Protokół - ${item}`)
                                                    }}
                                                />
                                            ))}
                                        </ErrorWrapper>
                                    </>
                                )}
                                {client.length > 0 && (
                                    <>
                                        <h4>Klient</h4>
                                        <ErrorWrapper>
                                            {client.map((item) => (
                                                <MissingTask
                                                    item={item}
                                                    closure={setModal(`Klient - ${item}`)}
                                                />
                                            ))}
                                        </ErrorWrapper>
                                    </>
                                )}
                                {vehicles.length > 0 && (
                                    <>
                                        <h4>Samochód/Przedmiot</h4>
                                        <ErrorWrapper>
                                            {vehicles.map((item) => {
                                                return item.map((field) => (
                                                    <MissingTask
                                                        item={field}
                                                        closure={() => {
                                                            setModal(`Samochód/Przedmiot - ${field}`)
                                                        }}
                                                    />
                                                ))
                                            })}
                                        </ErrorWrapper>
                                    </>
                                )}
                                {finances.length > 0 && (
                                    <>
                                        <h4>Finansowanie</h4>
                                        <ErrorWrapper>
                                            {finances.map((item) => {
                                                return item.map((field) => (
                                                    <MissingTask
                                                        item={field}
                                                        closure={() => {
                                                            setModal(`Finansowanie - ${field}`)
                                                        }}
                                                    />
                                                ))
                                            })}
                                        </ErrorWrapper>
                                    </>
                                )}
                                {registration.length > 0 && (
                                    <>
                                        <h4>Rejestracja/Płatność</h4>
                                        <ErrorWrapper>
                                            {registration.map((item) => (
                                                <MissingTask
                                                    item={item}
                                                    closure={() => {
                                                        setModal(`Rejestracja/Płatność - ${item}`)
                                                    }}
                                                />
                                            ))}
                                        </ErrorWrapper>
                                    </>
                                )}
                                {policy.length > 0 && (
                                    <>
                                        <h4>Polisa</h4>
                                        <ErrorWrapper>
                                            {policy.map((item) => (
                                                <MissingTask
                                                    item={item}
                                                    closure={() => {
                                                        setModal(`Polisa - ${item}`)
                                                    }}
                                                />
                                            ))}
                                        </ErrorWrapper>
                                    </>
                                )}
                                {gap.length > 0 && (
                                    <>
                                        <h4>GAP</h4>
                                        <ErrorWrapper>
                                            {gap.map((item) => (
                                                <MissingTask
                                                    item={item}
                                                    closure={() => {
                                                        setModal(`GAP - ${item}`)
                                                    }}
                                                />
                                            ))}
                                        </ErrorWrapper>
                                    </>
                                )}
                            </BottomWrapper>
                            {!archived && (
                                <>
                                    {(archiveStatus.client && archiveStatus.vehicle && archiveStatus.policy) ? (
                                        <Button type="button" onClick={() => {
                                            setArchiveModal(true);
                                        }}>Archiwizuj</Button>
                                    ) : (
                                        <DisabledButton inactive disabled>Archiwizuj</DisabledButton>
                                    )}
                                </>
                            )}

                            {!archived && (
                                <>
                                    <Button type="button" onClick={() => {
                                        setCloseTaskModal(true);
                                    }}>Zamknij zadanie</Button>
                                </>
                            )}

                            {archiveModal && <Modal closeModal={() => {
                                setArchiveModal(false)
                            }}>
                                <P>Czy na pewno chcesz archiwizować zadanie ?</P>

                                <ModalButtonWrapper>
                                    <Button>Tak</Button>
                                    <Button type="button" inactive onClick={() => setArchiveModal(false)}>NIE</Button>
                                </ModalButtonWrapper>
                            </Modal>}

                            {closeTaskModal && <Modal closeModal={() => {
                                setCloseTaskModal(false)
                            }}>
                                <P>Czy na pewno chcesz zakończyć zadanie ?</P>

                                <ModalButtonWrapper>
                                    <Button type="button" onClick={() => closeTask()}>Tak</Button>
                                    <Button type="button" inactive onClick={() => setCloseTaskModal(false)}>NIE</Button>
                                </ModalButtonWrapper>
                            </Modal>}
                        </form>
                    )}
                />
            </>}

            {modal && (
                <Modal closeModal={() => {
                    setModal(null)
                }}>
                    <MissingTaskForm
                        toDoId={toDoId}
                        description={modal}
                        closeModal={setModal}
                        update={() => {
                            setChange(change + 1);
                        }
                        }
                    />
                </Modal>
            )}
        </>
    );
}
