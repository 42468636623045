import React, {useEffect, useState} from 'react';
import {StyledFontAwesomeIcon, StyledLink} from "../../components/ListComponents";
import {faArrowRight, faFileDownload, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import styled from "styled-components";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";
import {Button} from "../../components/Buttons";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {NEW_TO_DO} from "../../actions";
import {downloadPackage} from "../../helpers/file";
import {List} from "../../components/List";

const StyledButton = styled.button`
  border: none;
  background: transparent;
  color: ${({theme: {colors: {red}}}) => red};;
  font-weight: 900;
  font-size: 1.6rem;
  top: 5px;
  right: 5px;
`;

const NewStatus = styled.span`
    color: red;
`;

export const ToDoListTable = ({archived, category}) => {
    const [toDo, setToDo] = useState([]);
    const token = useSelector((store) => store.token);
    const dispatch = useDispatch();
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState("");
    const [sortDirection, setSortDirection] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchParam, setSearchParam] = useState("");
    const [openModal, setOpenModal] = useState(null);


    useEffect(() => {
        loadData();
    }, [token, archived, page, rowsPerPage, sort, sortDirection, searchParam])

    useEffect(() => {
        if (openModal === false) {
            loadData();
        }
    }, [openModal])

    const loadData = () => {
        axios.get(`${apiUrl}/toDo`, {
            params: {
                archived: archived,
                category: category,
                page: page,
                rowsPerPage: rowsPerPage,
                sort: sort,
                sortDirection: sortDirection,
                search: searchParam
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {toDos, total, countToDoWithNewMessages}}) => {
            setToDo(toDos);
            setTotal(total);
            dispatch({
                type: NEW_TO_DO,
                payload: {newToDo:countToDoWithNewMessages}
            });
        });
    }

    const tableColumns = [
        {name: 'ID', property: 'id'},
        {name: 'Data utworzenia', property: 'createdAt', type: 'sort'},
        {name: 'Imię i nazwisko/Firma', property: 'name', relationProperty: 'value'},
        {name: 'Pojazd marka', property: 'vehicleMark'},
        {name: 'Cena netto', property: 'vehiclePriceNet'},
        {name: 'Finansujący', property: 'institution'},
        {name: 'Samochód/Przedmiot', property: 'vehicle_status'},
        {name: 'Finansowanie', property: 'finance_status'},
        {name: 'Oferta polisy', property: 'policy_offer_status'},
        {name: 'Rejestracja/Płatności', property: 'registration_status'},
        {name: 'Polisa', property: 'policy_status'},
        {name: 'Status zmieniony', property: 'status_updated_at'},
        {name: 'Wznowienie', property: 'duplicate'},
        {name: 'Dni do końca', property: 'policyEnd'},
    ];

    const callbackFunction = (pageChanged, rowsPerPageChanged, sortChanged, sortDirectionChanged, searchParamChanged) => {
        setPage(pageChanged + 1);
        setRowsPerPage(rowsPerPageChanged);
        setSort(sortChanged);
        setSortDirection(sortDirectionChanged);
        setSearchParam(searchParamChanged);
    }

    const cells = (element) => {
        return (
            <>
                <td style={{color: element.status ? 'red' : 'inherit'}}>{element.id}</td>
                <td>{element.createdAt}</td>
                <td>{element.name}</td>
                <td>{element.vehicleMark ?? '-'}</td>
                <td>{element.vehiclePriceNet ? element.vehiclePriceNet.toFixed(2) : '-'}</td>
                <td>{element.institution ?? '-'}</td>
                <td>{element.vehicle_status}</td>
                <td>{element.finance_status}</td>
                <td>{element.policy_offer_status}</td>
                <td>{element.registration_status}</td>
                <td>{element.policy_status}</td>
                <td>{element.status_updated_at ?? '-'}</td>
                <td>
                    {element.duplicate ? (
                        <>
                            {element.parent_id !== null ? (
                                <Link to={`/zadania/${element.parent_id}`}>
                                    Tak
                                </Link>
                            ) : (<>Tak</>)}
                        </>
                    ) : (
                        <>Nie</>
                    )}
                </td>
                <td>{element.policyEnd}</td>
            </>
        );
    }

    const actions = (element) => {
        return (
            <>
                <StyledButton onClick={() => {
                    setOpenModal(element.id);
                }}>
                    <StyledFontAwesomeIcon size="2x" icon={faTrashAlt} fixedWidth/>
                </StyledButton>
                <StyledLink onClick={()=>{
                    downloadPackage(token, 'toDo', element.id);
                }}>
                    <StyledFontAwesomeIcon size="2x" icon={faFileDownload} fixedWidth/>
                </StyledLink>
                <Link to={`/zadania/${element.id}`}>
                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                </Link>
            </>
        )
    }

    return (
        <>

        <List
            tableColumns={tableColumns}
            cells={cells}
            data={toDo}
            actions={actions}
            readonly={false}
            initialTotal={total}
            parentCallback={callbackFunction}
            search={true}
        ></List>

            {openModal && <Modal closeModal={() => {
                setOpenModal(false)
            }}>
                <P>Czy na pewno chcesz usunąć zadanie?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.delete(`${apiUrl}/toDo/${openModal}`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Zadanie zostało usunięte.");
                        }).catch((e) => {
                            toast.error("Nie możesz usunać zadania.");
                        }).finally(() => {
                            setOpenModal(false);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setOpenModal(false)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}
        </>
    );
};
