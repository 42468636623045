import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faBars,
    faBuilding,
    faCar,
    faCog,
    faFileAlt,
    faHandPointDown,
    faUser,
    faUsers,
    faWallet
} from '@fortawesome/free-solid-svg-icons';
import VazuviLogo from '../static/img/vazuvi-logo.png'
import {Link, Redirect, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {LOGOUT} from "../actions";

const StyledHeader = styled.header`
display: flex;
flex-direction: row;
justify-content: space-between;
background-color: ${({theme: {colors: {darkGray}}}) => darkGray};
`;

const LogoWrapper = styled(Link)`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
background-color: ${({theme: {colors: {white}}}) => white};
padding: 0 20px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
font-size: 3rem;
margin-bottom: 15px;
`;

const StyledLink = styled(Link)`
color: #ffffff;
text-decoration: none;
&>${StyledFontAwesomeIcon}{
  color: ${({active, theme: {colors: {white, red}}}) => active ? red : white};
}
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: 1.8rem;
margin: 0 20px;
`;

const StyledMenu = styled.ul`
display: flex;
flex-direction: row;
align-items: center;
list-style: none;
`;

const StyledButton = styled.button`
border: none;
background: transparent;
color: ${({theme: {colors: {red}}}) => red};;
font-weight: 900;
font-size: 1.6rem;
position: absolute;
top: 5px;
right: 5px;
`;

export const Header = () => {
    const token = useSelector((store) => store.token);
    const roleName = useSelector((store) => store.roleName);
    const newToDo = useSelector((store) => store.newToDo);
    const [redirect, setRedirect] = useState(null);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
    }, []);

    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <StyledHeader>
                <LogoWrapper to="/">
                    <img src={VazuviLogo} alt=""/>
                </LogoWrapper>
                {!!token && <>
                    <StyledMenu>
                        <li>
                            <StyledLink to="/dashboard" active={location.pathname.includes('dashboard')}>
                                <StyledFontAwesomeIcon icon={faBars} fixedWidth/>
                                <span>Dashboard</span>
                            </StyledLink>
                        </li>
                        <li>
                            <StyledLink to="/klienci" active={location.pathname.includes('klienci')}>
                                <StyledFontAwesomeIcon icon={faUsers} fixedWidth/>
                                <span>Klienci</span>
                            </StyledLink>
                        </li>
                        <li>
                            <StyledLink to="/pojazdy" active={location.pathname.includes('pojazdy')}>
                                <StyledFontAwesomeIcon icon={faCar} fixedWidth/>
                                <span>Pojazdy</span>
                            </StyledLink>
                        </li>
                        <li>
                            <StyledLink to="/polisy" active={location.pathname.includes('polisy')}>
                                <StyledFontAwesomeIcon icon={faFileAlt} fixedWidth/>
                                <span>Polisy</span>
                            </StyledLink>
                        </li>
                        <li>
                            <StyledLink to="/zadania" active={location.pathname.includes('zadania')}>
                                <StyledFontAwesomeIcon icon={faHandPointDown} fixedWidth/>
                                <span>To Do {newToDo !== 0 ? newToDo : ''}</span>
                            </StyledLink>
                        </li>
                        <li hidden={['Partner','Administrator procesów'].includes(roleName)}>
                            <StyledLink to="/firmy" active={location.pathname.includes('firmy')}>
                                <StyledFontAwesomeIcon icon={faBuilding} fixedWidth/>
                                <span>Baza TU</span>
                            </StyledLink>
                        </li>
                        <li hidden={['Partner', 'Administrator procesów'].includes(roleName)}>
                            <StyledLink to="/uzytkownicy" active={location.pathname.includes('uzytkownicy')}>
                                <StyledFontAwesomeIcon icon={faUser} fixedWidth/>
                                <span>Użytkownicy</span>
                            </StyledLink>
                        </li>
                        <li hidden={['Administrator procesów'].includes(roleName)}>
                            <StyledLink to="/portfele" active={location.pathname.includes('portfele')}>
                                <StyledFontAwesomeIcon icon={faWallet} fixedWidth/>
                                <span>Portfele</span>
                            </StyledLink>
                        </li>
                        <li>
                            <StyledLink to="/ustawienia" active={location.pathname.includes('ustawienia')}>
                                <StyledFontAwesomeIcon icon={faCog} fixedWidth/>
                                <span>Ustawienia</span>
                            </StyledLink>
                        </li>
                    </StyledMenu>
                    <StyledButton onClick={() => {
                        dispatch({type: LOGOUT});
                        setRedirect('/');
                    }}>WYLOGUJ</StyledButton>
                </>}

            </StyledHeader>
        </>
    );
};
