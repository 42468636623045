import React, {useEffect, useState} from 'react';
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {InputFloating, SelectFloating} from "../../components/Form";
import {Button} from "../../components/Buttons";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {toast, ToastContainer} from "react-toastify";
import {nip} from "../../validators/nip";
import {StyledFontAwesomeIcon, StyledInlineLink} from "../../components/ListComponents";
import {faWallet} from "@fortawesome/free-solid-svg-icons";

const StyledForm = styled.form`
max-width: 100%;
width: 460px;
`;

export const EditUser = ({match}) => {
    const [roles, setRoles] = useState([]);
    const token = useSelector((store) => store.token);
    const readOnly = ['Partner', 'Księgowy'].includes(useSelector((store) => store.roleName));
    const roleName = useSelector((store) => store.roleName);
    const [user, setUser] = useState({});

    useEffect(() => {
        axios.get(`${apiUrl}/role`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {roles: newRoles}}) => {
            setRoles(newRoles);
        });

        axios.get(`${apiUrl}/user/${match.params.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {user: newUser}}) => {
            setUser(newUser);
        });
    }, [match.params.id, token]);

    const validate = (values) => {
        const errors = {}
        if (!values.email) {
            errors.email = 'Uzupełnij login'
        }
        if (!values.name) {
            errors.name = 'Uzupełnij imię i nazwisko'
        }
        if (!values.nip) {
            errors.nip = 'Uzupełnij NIP'
        }
        if (!nip(values.nip)) {
            errors.nip = 'NIP jest niepoprawny'
        }
        if (!values.commission) {
            errors.commission = 'Uzupełnij prowizję'
        }
        if (values.commission && (values.commission < 0 || values.commission >= 100)) {
            errors.commission = 'Prowizja powinna być w przedziale 0-100'
        }
        if (!values.phone) {
            errors.phone = 'Uzupełnij telefon'
        }
        if (!values.role_id) {
            errors.role_id = 'Wybierz rolę'
        }
        if (values.password !== values.password_confirmation) {
            errors.password_confirmation = 'Hasła są różne'
        }
        return errors;
    }

    return (
        <PanelContentWrapper>
            <ToastContainer
                position="bottom-right"
            />
            <h2>Edytuj użytkownika</h2>
            <p><StyledInlineLink to={`/portfele/${user?.wallet?.id}`}><StyledFontAwesomeIcon icon={faWallet}
                                                                                             fixedWidth/></StyledInlineLink> Stan
                konta: {parseFloat(user?.wallet?.value).toFixed(2)} zł</p>
            <Form
                validate={values => validate(values)}
                onSubmit={async values => {
                    let errors = {}
                    await axios.put(`${apiUrl}/user/${match.params.id}`, values, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }).then(({data: {user: newUser}}) => {
                        setUser(newUser);
                        toast.success("Twoje dane zostały zapisane");
                    }).catch((error) => {
                        const errorTexts = {
                            email: 'Użytkownik z tym adresem e-mail już istnieje'
                        };
                        if (error.response.data.errors.email) {
                            errors.email = errorTexts['email'];
                        }
                    });
                }}
                initialValues={user}
                render={({handleSubmit}) => (
                    <StyledForm onSubmit={handleSubmit}>
                        <fieldset disabled={readOnly} style={{display: "contents"}}>
                            <Field
                                name="email"
                                type="email"
                                render={({input, meta}) => (
                                    <InputFloating label="Login" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="name"
                                render={({input, meta}) => (
                                    <InputFloating label="Imię i nazwisko" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="phone"
                                render={({input, meta}) => (
                                    <InputFloating label="Numer telefonu" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="nip"
                                render={({input, meta}) => (
                                    <InputFloating label="NIP" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="commission"
                                type="number"
                                step="0.01"
                                render={({input, meta}) => (
                                    <InputFloating label="Prowizja od ubezpieczenia w %" {...input} {...meta}/>
                                )}
                            />
                            {roleName !== 'Partner' ?
                                <Field
                                    name="role_id"
                                    render={({input, meta}) => (
                                        <SelectFloating label="Uprawnienia" {...input} {...meta}>
                                            <option value="">Uprawienia</option>
                                            {roles.map(({id, name}) => (
                                                <option value={id}>{name}</option>
                                            ))}
                                        </SelectFloating>
                                    )}
                                /> : ''}
                            {!readOnly && <Button>Zapisz</Button>}
                        </fieldset>
                    </StyledForm>
                )}
            />
        </PanelContentWrapper>
    );
};
