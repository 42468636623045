import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {Button} from "../../components/Buttons";
import {List} from "../../components/List";

export const VehiclesToSelect = ({addVehicleToClient}) => {
    const [vehicles, setVehicles] = useState([]);
    const token = useSelector((store) => store.token);

    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState("");
    const [sortDirection, setSortDirection] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchParam, setSearchParam] = useState("");

    const loadData = () => {
        axios.get(`${apiUrl}/vehicle/withoutClient`, {
            params: {
                page,
                search: searchParam
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {vehicles: newVehicles, total}}) => {
            setVehicles(newVehicles);
            setTotal(total);
        });
    }


    useEffect(() => {
        loadData();
    }, [token, page, rowsPerPage, sort, sortDirection, searchParam])

    const tableColumns = [
        {name: 'Nr rejestracyjny', property: 'id'}
    ];

    const callbackFunction = (pageChanged, rowsPerPageChanged, sortChanged, sortDirectionChanged, searchParamChanged) => {
        setPage(pageChanged + 1);
        setRowsPerPage(rowsPerPageChanged);
        setSort(sortChanged);
        setSortDirection(sortDirectionChanged);
        setSearchParam(searchParamChanged);
    }

    const cells = (element) => {
        return (
            <>
                <td>{element.registrationNumber ?? '-'}</td>
            </>
        );
    }

    const actions = (element) => {
        return (
            <>
                <Button type="button" onClick={() => {
                    addVehicleToClient(element.id);
                }}>Dodaj</Button>
            </>
        )
    }

    return (
        <>
            <List
                tableColumns={tableColumns}
                cells={cells}
                data={vehicles}
                actions={actions}
                readonly={false}
                initialTotal={total}
                parentCallback={callbackFunction}
            ></List>
        </>
    );
};
