import React from 'react';
import styled from "styled-components";

const StyledModal = styled.div`
    position: fixed;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    &>div{
        background: #fff;
        max-width: 90%;
        max-height: 90%;
        padding: 30px;
        position: relative;
        overflow: auto;
    }
`;
const CloseButton = styled.span`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    width: 20px;
    height: 20px;
    font-weight: bold;
    font-size: 25px;
`;
export const Modal = ({closeModal, children, ...props}) => {
    return (
        <StyledModal {...props}>
            <div>
                <CloseButton className="btn btn-default" onClick={closeModal}>X</CloseButton>
                {children}
            </div>
        </StyledModal>
    );
};
