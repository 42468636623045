import firebase from 'firebase/app';
import 'firebase/messaging';

var firebaseConfig = {
    apiKey: "AIzaSyCX2KQYt2J34xu31li7SOtJXRW9UyPcZ0U",
    authDomain: "vazuvi-48e1c.firebaseapp.com",
    projectId: "vazuvi-48e1c",
    storageBucket: "vazuvi-48e1c.appspot.com",
    messagingSenderId: "469201867278",
    appId: "1:469201867278:web:31c819b1f28661842f225f",
    measurementId: "G-CK63QCRWCE"
};

firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

export const getToken = () => {
    return messaging.getToken({vapidKey: 'BNLKqzfIWeT9vcBA2euYs2I0AT2SJvsCo1wIGOGgIhNtBM3KUGuu1tXzCCB9A4WfhYd-PQ6-26n-3o5jPYYseQ0'});
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });
