import React, {useEffect, useState} from 'react';
import {Input, Label, RoundButton, Select, Switch, TopFormWrapper} from "../../components/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import {Button} from "../../components/Buttons";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {toast} from "react-toastify";
import {Status} from "../../components/Status";
import {Files} from "../../components/Files";
import arrayMutators from "final-form-arrays";
import {createFormData} from "../../helpers/form";
import {File} from "../../components/File";
import {Comment} from "../Comment/Comment";

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 140px 320px;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledDivError = styled.div`
  color: red;
`;

const FieldSet = styled.fieldset`
  border: none;
`;

export const RegistrationTab = ({toDoId, archived}) => {
    const token = useSelector((store) => store.token);
    const [isEnabled, setIsEnabled] = useState(false);
    const [isRenew, setIsRenew] = useState(false);
    const [registration, setRegistration] = useState({});
    const [clientName, setClientName] = useState(null);
    const [slack, setSlack] = useState(null);
    const [invoiceScan, setInvoiceScan] = useState(null);
    const [leasingScan, setLeasingScan] = useState(null);
    const [newRegistrationNumberScan, setNewRegistrationNumberScan] = useState(null);
    const [status, setStatus] = useState({});
    const [statuses, setStatuses] = useState([]);
    const [globalFiles, setGlobalFiles] = useState([]);

    useEffect(() => {
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/toDos/${toDoId}/registration`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({
                         data: {
                             registration: newRegistration,
                             isActive,
                             clientFullName,
                             statuses: newStatuses,
                             registration_status: newStatus,
                             isDuplicated,
                             slack
                         }
                     }) => {
                setIsEnabled(isActive);
                setRegistration(newRegistration);
                setClientName(clientFullName);
                setSlack(slack);
                setStatus(newStatus);
                setStatuses(newStatuses);
                setIsRenew(isDuplicated)
            });
        }
    }, [token, toDoId]);

    const validate = (values) => {
        const errors = {}

        return errors;
    }

    const setFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        if (globalFiles.some(e => e.index === index)) {
            let newArr = [...globalFiles].map((item) => {
                if (item.index === index) {
                    return obj;
                }
                return item;
            });
            setGlobalFiles(newArr);
        } else {
            setGlobalFiles(prevState => [...prevState, obj]);
        }
    }

    return (
        <>
            <Switch checked={isEnabled} onChange={() => {
                axios.post(`${apiUrl}/toDos/${toDoId}/registration/toggle`, {
                    isActive: !isEnabled,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }).then(({data: {registration: newRegistration}}) => {
                    setRegistration(newRegistration);
                });
                setIsEnabled(!isEnabled)
            }}/>
            <TopFormWrapper>
                <div>
                    <h2>Rejestracja/Płatność</h2>
                    {isEnabled && <RoundButton>
                        <FontAwesomeIcon icon={faPlus} color="white"/>
                    </RoundButton>}
                </div>
                <div>
                    {clientName && (
                        clientName.map((item, key) => (
                            <h2 key={key}>Dla {item}</h2>
                        ))
                    )}

                    <div className="links">
                        <h2>Slack:</h2>
                        {slack && slack['vehicleSlackLink'] && (
                            <a href={slack['vehicleSlackLink']} target="_blank">
                                <FontAwesomeIcon icon={faArrowRight} color="black" fixedWidth />
                                Samochód
                            </a>
                        )}

                        {slack && slack['policySlackLink'] && (
                            <a href={slack['policySlackLink']} target="_blank">
                                <FontAwesomeIcon icon={faArrowRight} color="black" fixedWidth />
                                Polisa
                            </a>
                        )}
                    </div>
                </div>
            </TopFormWrapper>

            {isEnabled && <>
                <Status
                    todoId={toDoId}
                    initialValues={{registration_status: status}}
                    nameField="registration_status"
                    statuses={statuses}
                    disabled={archived || isRenew}
                />
                <Form
                    mutators={{
                        setValue: ([field, value], state, {changeValue}) => {
                            changeValue(state, field, () => value)
                        },
                        // potentially other mutators could be merged here
                        ...arrayMutators
                    }}
                    validate={values => validate(values)}
                    onSubmit={values => {
                        const formData = new FormData();
                        Object.keys(values).forEach((key) => {
                            let value = values[key] ?? '';
                            if (value === true) {
                                value = 1;
                            } else if (value === false) {
                                value = 0;
                            }
                            formData.append(key, value)
                        });

                        if (newRegistrationNumberScan) {
                            formData.append('newRegistrationNumberScan', newRegistrationNumberScan);
                        }

                        if (invoiceScan) {
                            formData.append('invoiceScan', invoiceScan);
                        }

                        if (leasingScan) {
                            formData.append('leasingScan', leasingScan);
                        }

                        createFormData(formData, 'files', values.files);
                        if (globalFiles.length > 0) {
                            globalFiles.forEach(({files}) => {
                                files.forEach((file) => {
                                    formData.append(`files[${file.index}][file]`, file.file);
                                })
                            });
                        }

                        axios.post(`${apiUrl}/toDos/${toDoId}/registration`, formData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {registration: newRegistration, isActive}}) => {
                            setIsEnabled(isActive);
                            setRegistration(newRegistration);
                            setInvoiceScan(null);
                            setLeasingScan(null);
                            setNewRegistrationNumberScan(null);
                            toast.success("Dane zostały zapisane.");
                        }).catch(() => {
                            toast.error("Popraw dane");
                        });
                    }}
                    initialValues={{...registration, isActive: 1}}
                    render={({handleSubmit, form, values}) => (
                        <form onSubmit={handleSubmit}>
                            <FieldSet disabled={isRenew}>
                            {/*<FieldSet>*/}
                                <Field
                                    name='ul'
                                    type="date"
                                    parse={value => (value === "" ? null : value)}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Podpisanie UL</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                                {meta.error && meta.touched &&
                                                    <StyledDivError>{meta.error}</StyledDivError>}
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='number'
                                    parse={value => (value === "" ? null : value)}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Numer umowy*</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>
                                <Field
                                    name='ow'
                                    type="date"
                                    parse={value => (value === "" ? null : value)}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Wpłata OW</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                                {meta.error && meta.touched &&
                                                    <StyledDivError>{meta.error}</StyledDivError>}
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='orderInvoice'
                                    type="date"
                                    parse={value => (value === "" ? null : value)}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Zamówienie FV</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                                {meta.error && meta.touched &&
                                                    <StyledDivError>{meta.error}</StyledDivError>}
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='invoice'
                                    type="date"
                                    parse={value => (value === "" ? null : value)}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Faktura</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                                {meta.error && meta.touched &&
                                                    <StyledDivError>{meta.error}</StyledDivError>}
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='paymentInvoice'
                                    parse={value => (value === "" ? null : value)}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Płatność za FV</Label>
                                                <Select {...input} id={input.name}>
                                                    <option value="Przed rejestracją">Przed rejestracją</option>
                                                    <option value="Po rejestracji">Po rejestracji</option>
                                                </Select>
                                                {meta.error && meta.touched &&
                                                    <StyledDivError>{meta.error}</StyledDivError>}
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='canRegister'
                                    type="checkbox"
                                    parse={value => (value === "" ? null : value)}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Zgoda na rejestrację</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='shipDocuments'
                                    type="date"
                                    parse={value => (value === "" ? null : value)}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Wysyłka dokumentów do rejestracji</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='documentsInFaculty'
                                    type="date"
                                    parse={value => (value === "" ? null : value)}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Dokumenty w Wydziale Komunikacji</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='vin'
                                    parse={value => (value === "" ? null : value)}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>VIN dla pojazd nowy</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                                {meta.error && meta.touched &&
                                                    <StyledDivError>{meta.error}</StyledDivError>}
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='registrationDate'
                                    type="date"
                                    parse={value => (value === "" ? null : value)}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Data zarejestrowania</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                                {meta.error && meta.touched &&
                                                    <StyledDivError>{meta.error}</StyledDivError>}
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='newRegistrationNumber'
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Nowy nr rejestracyjny</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                                {meta.error && meta.touched &&
                                                    <StyledDivError>{meta.error}</StyledDivError>}
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <Field
                                    name='paidAt'
                                    type="date"
                                    parse={value => (value === "" ? null : value)}
                                    render={({input, meta}) => (
                                        <>
                                            <InputWrapper>
                                                <Label>Zapłacono</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                                {meta.error && meta.touched &&
                                                    <StyledDivError>{meta.error}</StyledDivError>}
                                            </InputWrapper>
                                        </>
                                    )}/>

                                <File
                                    name="newRegistrationNumberScan_path"
                                    label="Nowy dowód rejestracyjny"
                                    onFileSelect={(file) => setNewRegistrationNumberScan(file)}
                                    initialValue={values.newRegistrationNumberScanUrl}
                                />

                                <File
                                    name="invoiceScan_path"
                                    label="Faktura"
                                    onFileSelect={(file) => setInvoiceScan(file)}
                                    initialValue={values.invoiceScanUrl}
                                />

                                <File
                                    name="leasingScan_path"
                                    label="Umowa leasingu"
                                    onFileSelect={(file) => setLeasingScan(file)}
                                    initialValue={values.leasingScanUrl}
                                />

                                <Files name="files" index={0} setFilesToForm={setFilesToForm} disabled={isRenew}/>
                            </FieldSet>

                            {(!archived && !isRenew) && (
                                <Button>Zapisz</Button>
                            )}
                        </form>
                    )}
                />
            </>}
            <Comment toDoId={toDoId} toDoTab={"registration"} vehicleId={null} policyId={null} archived={false} history={false} />
        </>
    );
};
