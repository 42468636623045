import React, {useEffect, useState} from 'react';
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {FileButton, Input, InputFileWrapper, InputFloating, Label} from "../../components/Form";
import {Button} from "../../components/Buttons";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {toast, ToastContainer} from "react-toastify";
import {StyledFontAwesomeIcon, StyledInlineLink, StyledLink} from "../../components/ListComponents";
import {faEye, faSave, faUser} from "@fortawesome/free-solid-svg-icons";
import {Table} from "../../components/Table";
import moment from "moment";
import {StyledTextarea} from "../../components/styled/inputs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ReviewModal} from "../../components/RevierModal";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";
import {Redirect} from "react-router-dom";

const StyledForm = styled.form`
  max-width: 100%;
  width: 460px;
  margin-bottom: 20px;
`;

const StyledH2 = styled.h2`
  margin-right: 5px;
`;

const StyledA = styled.a`
  color: black;
  text-decoration: none;
  margin-left: 5px;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;

  div {
    display: flex;
    margin-right: 30px;
  }

  margin-bottom: 10px;
`;

const StyledWalletTextarea = styled(StyledTextarea)`
  margin-bottom: 10px;
`;

const WalletHistoryTable = styled(Table)`
  tbody {
    tr, tr:nth-child(even) { {
      height: 30px;
    }
    }
`;

export const Wallet = ({match}) => {
    const token = useSelector((store) => store.token);
    const fullAccess = ['Współwłaściciel', 'Księgowy'].includes(useSelector((store) => store.roleName));

    const [modal, setModal] = useState(false);
    const [paidModal, setPaidModal] = useState(false);
    const [historyId, setHistoryId] = useState({});
    const [fileUrl, setFileUrl] = useState(null);
    const [wallet, setWallet] = useState({});
    const [attachment, setAttachment] = useState(null);
    const types = {
        'fee_insurance': 'Prowizja od ubezpieczenia',
        'fee_gap': 'Prowizja od GAP',
        'fee_leasing': 'Prowizja od leasingu/kredytu',
        'fee_car_sale': 'Prowizja od sprzedaży samochodu',
        'withdraw': 'Wypłata',
        'correction': 'Korekta'
    };
    useEffect(() => {
        getData();
    }, [match.params.id, token]);

    const getData = () => {
        axios.get(`${apiUrl}/wallets/${match.params.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {wallet: newWallet}}) => {
            setWallet(newWallet);
        });
    }

    const validate = (values) => {
        const errors = {}
        if (!values.value) {
            errors.value = 'Uzupełnij wartość';
        }

        if ((!values.operationType || values.operationType === 'withdraw') && parseFloat(values.value) < 0) {
            errors.value = 'Kwota nie może być mniejsza niż 0';
        }

        if (values.value && parseFloat(values.value) > parseFloat(wallet.value)) {
            errors.value = 'Masz za mało środków';
        }
        return errors;
    }

    const getOperationValue = (value) => {
        const floatValue = parseFloat(value);

        if (floatValue === 0) {
            return "0";
        }

        if (floatValue < 0) {
            return floatValue.toFixed(2);
        }

        if (floatValue > 0) {
            return `+${floatValue.toFixed(2)}`;
        }
    }

    const getRowColor = (operationType, value) => {
        if (operationType === 'correction') {
            return parseFloat(value) < 0 ? 'red' : 'green';
        } else {
            return 'inherit';
        }
    }

    const changePaidStatus = () => {
        axios.post(`${apiUrl}/wallets/togglePaid/${historyId}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {isPaid: {isPaid}}}) => {
            toast.success("Status płatności został zmieniony.");
            setPaidModal(false);
            getData();
        }).catch(e => {
            toast.error("Coś poszło nie tak.");
            console.log(e.response)
        });
    };

    return (
        <>
            <PanelContentWrapper>
                <ToastContainer
                    position="bottom-right"
                />
                <h2>Portfel</h2>
                <h3>Stan konta: {parseFloat(wallet?.value).toFixed(2)} zł</h3>
                {wallet?.user && <p><StyledInlineLink to={`/uzytkownicy/${wallet?.user?.id}`}>
                    <StyledFontAwesomeIcon icon={faUser} fixedWidth/>
                </StyledInlineLink> {wallet?.user?.name} {wallet?.user?.email}</p>}
                {fullAccess && <Form
                    validate={values => validate(values)}
                    onSubmit={(values, form) => {
                        const formData = new FormData();
                        if (!values.hasOwnProperty('operationType')) {
                            formData.append('operationType', 'withdraw');
                        } else {
                            formData.append('operationType', values.operationType);
                        }

                        formData.append('value', values.value);
                        formData.append('comment', values.comment ?? '');

                        if (attachment) {
                            formData.append('attachment', attachment);
                        }
                        axios.post(`${apiUrl}/wallets/${match.params.id}/withdraw`, formData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {wallet: newWallet}}) => {
                            setWallet(newWallet);
                            setAttachment(null);
                            form.restart();
                            toast.success("Operacja została zlecona");
                        }).catch(() => {
                            toast.error("Wystąpił błąd");
                        });
                    }}
                    render={({handleSubmit}) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <RadioWrapper>
                                <Field
                                    name='operationType'
                                    type="radio"
                                    value="withdraw"
                                    render={({input, meta}) => (
                                        <>
                                            <div>
                                                <Label htmlFor={`${input.name}-n`}><StyledH2>Zleć
                                                    wypłatę</StyledH2></Label>
                                                <Input {...input} id={`${input.name}-n`} placeholder="" checked={true}/>
                                            </div>
                                        </>
                                    )}/>
                                <Field
                                    name='operationType'
                                    type="radio"
                                    value="correction"
                                    render={({input, meta}) => (
                                        <>
                                            <div>
                                                <Label htmlFor={`${input.name}-y`}><StyledH2>Zleć
                                                    korektę</StyledH2></Label>
                                                <Input {...input} id={`${input.name}-y`} placeholder=""/>
                                            </div>
                                        </>
                                    )}/>
                            </RadioWrapper>
                            <Field
                                name="value"
                                type="number"
                                render={({input, meta}) => (
                                    <InputFloating label="Wartość" {...input} {...meta}/>
                                )}
                            />
                            <Field
                                name="comment"
                                render={({input, meta}) => (
                                    <>
                                        <div>
                                            <StyledWalletTextarea name="" placeholder="Komentarz" cols="30"
                                                                  rows="4" {...input}/>
                                        </div>
                                    </>
                                )}/>

                            <Field
                                name="attachment"
                                type="file"
                                render={({input}) => (
                                    <>
                                        <InputFileWrapper
                                            htmlFor={input.name}
                                            onChange={(e) => {
                                                input.onChange(e);
                                                setAttachment(e.target.files[0]);
                                            }}
                                        >
                                            <input {...input} id={input.name}/>
                                            <span>Załącznik</span>
                                            <FileButton>{attachment ? attachment.name : "Wybierz plik"}</FileButton>
                                        </InputFileWrapper>
                                    </>
                                )}>
                            </Field>
                            <Button style={{marginTop: "2rem"}}>Wykonaj</Button>
                        </StyledForm>
                    )}
                />}
                <StyledH2>Historia</StyledH2>
                <WalletHistoryTable>
                    <thead>
                    <tr>
                        <th>Lp.</th>
                        <th>Wartość</th>
                        <th>ToDo</th>
                        <th>Typ</th>
                        <th>Komentarz</th>
                        <th>Data</th>
                        <th>Użytkownik</th>
                        <th>Klient</th>
                        <th>Doprowadzający</th>
                        <th>Zapłacone</th>
                        <th>Załącznik</th>
                    </tr>
                    </thead>
                    <tbody>
                    {wallet.history && wallet.history.map(({
                                                               id,
                                                               value,
                                                               toDoId,
                                                               operationType,
                                                               comment,
                                                               created_at,
                                                               user,
                                                               client,
                                                               leader,
                                                               attachment,
                                                               isPaid
                                                           }, key) => (
                        <tr key={id} style={
                            {color: getRowColor(operationType, value)}}>
                            <td>{key + 1}</td>
                            <td>{getOperationValue(value)} zł
                            </td>
                            <td>{toDoId ? <StyledLink to={`/zadania/${toDoId}`}>{toDoId}</StyledLink> : '-'}</td>
                            <td>{types[operationType]}</td>
                            <td>{comment}</td>
                            <td>{moment(created_at).format('DD-MM-YYYY HH:mm')}</td>
                            <td>{user}</td>
                            <td>{client}</td>
                            <td>{leader}</td>
                            <td><input
                                type="checkbox" checked={isPaid} name=""
                                onChange={() => {
                                    setPaidModal(true);
                                    setHistoryId(id);
                                }}/></td>
                            <td>{attachment && (
                                <div>
                                    <StyledA onClick={(e) => {
                                        e.preventDefault();
                                        setModal(true);
                                        setFileUrl(attachment);
                                    }}
                                             rel="noopener noreferrer"
                                             target="_blank">
                                        <FontAwesomeIcon icon={faEye} color="grey"
                                                         size="2x"/>
                                    </StyledA>

                                    <StyledA href={attachment}
                                             rel="noopener noreferrer"
                                             target="_blank">
                                        <FontAwesomeIcon icon={faSave} color="grey"
                                                         size="2x"/>
                                    </StyledA>
                                </div>
                            )}
                            </td>
                        </tr>
                    ))}

                    </tbody>
                </WalletHistoryTable>
                <ReviewModal
                    modal={modal}
                    fileUrl={fileUrl}
                    onClose={() => {
                        setModal(false);
                        setFileUrl(null);
                    }}
                ></ReviewModal>

                {paidModal && <Modal
                    closeModal={() => {
                        setPaidModal(false);
                    }}>
                    <P>Czy na pewno chcesz zmienić status płatności?</P>

                    <ModalButtonWrapper>
                        <Button type="button" onClick={() => changePaidStatus()}>Tak</Button>
                        <Button type="button" inactive onClick={() => setPaidModal(false)}>Nie</Button>
                    </ModalButtonWrapper>
                </Modal>}
            </PanelContentWrapper>
        </>
    );
};
