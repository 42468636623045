import React, {useState} from "react";
import {Modal} from "./Modal";
import {isImage, isPdf} from "../helpers/file";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {Document, Page, pdfjs} from "react-pdf";
import styled from "styled-components";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PageControls = styled.div`
    &>div {
        float: left;
    }
    &>div.pageNumbers {
        padding: 5px 5px 0px 5px;
    }
`
const PDFViewer = styled.div`
    margin-top: 20px;
`

const ImgView = styled.img`
    max-width: 100%;
`

export const ReviewModal = ({modal, fileUrl, onClose}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    return (
        <>
            {modal && <Modal closeModal={() => {
                onClose();
            }}>
                {isPdf(fileUrl) &&
                    <>
                        <PageControls>
                            <div><FontAwesomeIcon icon={faAngleLeft} color={pageNumber > 1 ? "red" : "grey"} size="2x"
                                                  onClick={() => {
                                                      if (pageNumber > 1) {
                                                          setPageNumber(pageNumber - 1)
                                                      }
                                                  }}/></div>
                            <div className="pageNumbers">{pageNumber} of {numPages}</div>
                            <div><FontAwesomeIcon icon={faAngleRight} color={pageNumber < numPages ? "red" : "grey"}
                                                  size="2x"
                                                  onClick={() => {
                                                      if (pageNumber < numPages) {
                                                          setPageNumber(pageNumber + 1)
                                                      }
                                                  }}/></div>
                        </PageControls>
                        <PDFViewer>
                            <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                                <Page pageNumber={pageNumber}/>
                            </Document>
                        </PDFViewer>
                    </>
                }

                {isImage(fileUrl) &&
                    <ImgView src={fileUrl} alt={fileUrl}/>
                }

            </Modal>}
        </>
    );
};