import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {
    FileButton,
    Input,
    RoundButton,
    RoundSimpleButton,
    Select,
    Switch,
    TopFormWrapper,
    VariantLabel
} from "../../components/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Field, Form} from "react-final-form";
import {Button} from "../../components/Buttons";
import {FieldArray} from "react-final-form-arrays";
import arrayMutators from 'final-form-arrays'
import axios from "axios";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Status} from "../../components/Status";
import {Files} from "../../components/Files";
import {createFormData} from "../../helpers/form";
import {Comment} from "../Comment/Comment";

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  & > div {
    margin-right: 20px;
  }

  & > div:first-child label {
    display: flex;
    max-width: 160px;
    font-size: 1rem;
    padding: 6px 0;
    margin-bottom: 11px;
    align-items: center;
  }
`;
const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 140px 320px;
  align-items: center;
  margin-bottom: 10px;
`;

const InputWrapperWithoutLabel = styled(InputWrapper)`
  grid-template-columns: 240px;
  align-items: center;
  text-align: center;
  
  & > input:disabled {
    border: 1px solid #fff;
  }
`;

const StyledA = styled.a`
  color: black;
  text-decoration: none;
  margin-left: 10px;
`;

const InputFileWrapper = styled.label`
  display: grid;
  grid-template-columns: 140px auto auto;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1.2rem;
  position: relative;

  input {
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
  }
`;

const StyledDivError = styled.div`
  color: red;
`;

const StyledButton = styled(Button)`
  margin-right: 15px;
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  top: -20px;
  left: -5px;
`;

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
    div{
        margin-right: 20px;
    }
    &>div:first-child{
        padding-right: 20px;
        border-right: 1px solid black;
        p,h4{
            margin-top: 20px;
        }
    }
`;
const CustomerDataWrapper = styled.div`
  margin-bottom: 20px;
`;

export const GapTab = ({toDoId, archived}) => {
    const token = useSelector((store) => store.token);
    const [isEnabled, setIsEnabled] = useState(false);
    const [gaps, setGaps] = useState([{selected: false, fields_towing:[]}]);
    const [clientName, setClientName] = useState(null);
    const [slack, setSlack] = useState(null);
    const [client, setClient] = useState(null);
    const [status, setStatus] = useState({});
    const [statuses, setStatuses] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [globalFiles, setGlobalFiles] = useState([]);
    const [sumInsured, setSumInsured] = useState(null);
    const [cars, setCars] = useState(null);
    const [finances, setFinances] = useState(null);
    const [inquiry, setInquiry] = useState(null);

    useEffect(() => {
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/toDos/${toDoId}/gaps`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({
                         data: {
                             gaps: newGaps,
                             isActive,
                             sumInsured,
                             clientFullName,
                             slack,
                             statuses: newStatuses,
                             gap_status: newStatus,
                             insuranceCompanies,
                             clientData,
                             carsData,
                             financesData,
                             inquiryData
                         }
                     }) => {
                setIsEnabled(isActive);
                setClientName(clientFullName);
                setSlack(slack);
                setClient(clientData);
                setCars(carsData);
                setInquiry(inquiryData);
                setFinances(financesData);

                if (isActive) {
                    setGaps(newGaps);
                }
                setStatus(newStatus);
                setStatuses(newStatuses);
                setCompanies(insuranceCompanies);
                setSumInsured(sumInsured);
            });
        }
    }, [token, toDoId]);

    const setFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        if (globalFiles.some(e => e.index === index)) {
            let newArr = [...globalFiles].map((item) => {
                if (item.index === index) {
                    return obj;
                }
                return item;
            });
            setGlobalFiles(newArr);
        } else {
            setGlobalFiles(prevState => [...prevState, obj]);
        }
    }

    return (
        <>
            <Switch checked={isEnabled} onChange={() => (setIsEnabled(!isEnabled))}/>
            <TopFormWrapper>
                <div>
                    <h2>GAP</h2>
                    {isEnabled && <RoundButton>
                        <FontAwesomeIcon icon={faPlus} color="white"/>
                    </RoundButton>}
                </div>
                <div>
                    {clientName && (
                        clientName.map((item, key) => (
                            <h2 key={key}>Dla {item}</h2>
                        ))
                    )}

                    <div className="links">
                        <h2>Slack:</h2>
                        {slack && slack['vehicleSlackLink'] && (
                            <a href={slack['vehicleSlackLink']} target="_blank">
                                <FontAwesomeIcon icon={faArrowRight} color="black" fixedWidth />
                                Samochód
                            </a>
                        )}

                        {slack && slack['policySlackLink'] && (
                            <a href={slack['policySlackLink']} target="_blank">
                                <FontAwesomeIcon icon={faArrowRight} color="black" fixedWidth />
                                Polisa
                            </a>
                        )}
                    </div>
                </div>
            </TopFormWrapper>
            {isEnabled && <>
                <Status
                    todoId={toDoId}
                    initialValues={{gap_status: status}}
                    nameField="gap_status"
                    statuses={statuses}
                    disabled={archived}
                />
                <Form
                    mutators={{
                        setValue: ([field, value], state, {changeValue}) => {
                            changeValue(state, field, () => value)
                        },
                        // potentially other mutators could be merged here
                        ...arrayMutators
                    }}
                    onSubmit={values => {
                        const fd = new FormData();
                        createFormData(fd, 'gaps', values.gaps);
                        if(globalFiles.length > 0){
                            globalFiles.forEach(({index, files}) => {
                                files.forEach((file) => {
                                    fd.append(`gaps[${index}][files][${file.index}][file]`,file.file);
                                })
                            });
                        }
                        axios.post(`${apiUrl}/toDos/${toDoId}/gaps`, fd, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {gaps: newGaps, isActive}}) => {
                            setIsEnabled(isActive);
                            if (isActive) {
                                setGaps(newGaps);
                            }
                            toast.success("Dane zostały zapisane.");
                        }).catch(() => {
                            toast.error("Popraw dane");
                        });
                    }}
                    initialValues={{gaps}}
                    render={({handleSubmit, form, values}) => (
                        <form onSubmit={handleSubmit}>
                            <StyledWrapper>
                                <CustomerDataWrapper>
                                    {client !== null && (
                                        <>
                                            <h4>
                                                DANE KLIENTA
                                            </h4>
                                            <p>
                                                NIP: {client.client_nip ?? '-'} <br/>
                                                Działalność: {client.client_activity ?? '-'} <br/>
                                                Nazwa: {client.client_name ?? '-'} <br/>
                                                KRS: {client.client_krs ?? '-'}<br/>
                                                Ulica i numer lokalu: {client.client_street ?? '-'}<br/>
                                                Kod pocztowy: {client.client_postalCode ?? '-'}<br/>
                                                Miejscowość: {client.client_city ?? '-'}<br/>
                                                Numer telefonu: {client.client_phone ?? '-'}<br/>
                                                E-mail: {client.client_email ?? '-'}<br/>
                                            </p>
                                        </>
                                    )}

                                    {cars !== null && cars.length > 0 && (
                                        cars.map((car) => (
                                            <>
                                                <h4>
                                                    DANE SAMOCHODU WARIANT {car.variantNumber}
                                                </h4>
                                                <p>
                                                    Rodzaj przedmiotu: {car.item ?? '-'} <br/>
                                                    Stan: {car.condition ?? '-'}<br/>
                                                    Marka: {car.mark ?? '-'}<br/>
                                                    Model: {car.model ?? '-'}<br/>
                                                    Rocznik: {car.yearbook ?? '-'}<br/>
                                                    Data 1 rejestracji: {car.firstRegistration ?? '-'}<br/>
                                                    Numer rejestracyjny: {car.registrationNumber ?? '-'}<br/>
                                                    VIN: {car.vin ?? '-'}<br/>
                                                    Wersja wyposażenia: {car.equipmentVersion ?? '-'}<br/>
                                                    Przebieg: {car.mileage ?? '-'}<br/>
                                                    Cena netto: {car.net ?? '-'}
                                                </p>
                                            </>
                                        ))
                                    )}

                                    {inquiry !== null &&
                                    <p>Suma ubezpieczenia VAT: {inquiry.vat ?? '-'}<br/></p>}

                                    {finances !== null && finances.length > 0 && (
                                        finances.map(({variantNumber, institution, financingType}) => (
                                            <>
                                                <h4>
                                                    FINANSOWANIE WARIANT {variantNumber}
                                                </h4>
                                                <p>
                                                    Instytucja: {institution ?? '-'} <br/>
                                                    Rodzaj finansowania: {financingType ?? '-'}<br/>
                                                </p>
                                            </>
                                        ))
                                    )}
                                </CustomerDataWrapper>
                                <Grid>
                                <div>
                                    <label></label>
                                    <label>TU</label>
                                    <label>Oferta GAP w leasingu (mc)</label>
                                    <label>Oferta GAP w leasingu (suma)</label>
                                    <label>Okres finasowania w latach</label>
                                    <label>Suma ubezpieczenia</label>
                                    <label>Limit odszkodowania</label>
                                    <label>Prognozowana składka całkowita</label>
                                    <label>Prognozowana składka ratalna</label>
                                </div>
                                <FieldArray name="gaps">
                                    {({fields}) => (
                                        <>
                                            <ButtonsWrapper>
                                                <RoundSimpleButton
                                                    type="button"
                                                    onClick={() => fields.push({selected: false,}) && console.log('test')}>
                                                    <FontAwesomeIcon icon={faPlus} color="white"/>
                                                </RoundSimpleButton>
                                                <RoundSimpleButton
                                                    type="button"
                                                    onClick={() => fields.pop()}>
                                                    <FontAwesomeIcon icon={faMinus} color="white"/>
                                                </RoundSimpleButton>
                                            </ButtonsWrapper>

                                            {fields.map((name, index) => (
                                                <div>
                                                    <Field
                                                        name={`${name}.id`}
                                                        render={({input, meta}) => (
                                                            <Input {...input} id={input.name} hidden/>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.variantNumber`}
                                                        type="hidden"
                                                        initialValue={index + 1}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </>
                                                        )}
                                                    />

                                                    <Field
                                                        name={`${name}.selected`}
                                                        type="checkbox"
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <VariantLabel>
                                                                        Wariant {index + 1}
                                                                        <Input {...input} id={input.name}
                                                                               placeholder=""/>
                                                                    </VariantLabel>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.tu`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Select {...input} id={input.name} onChange={(e)=>{
                                                                        input.onChange(e);
                                                                        const company = companies.find(item => item.id === e.target.value);
                                                                        if(company && company?.fields_towing){
                                                                            form.mutators.setValue(`${name}.fields_towing`, company.fields_towing)
                                                                        }
                                                                        else{
                                                                            form.mutators.setValue(`${name}.fields_towing`, [])
                                                                        }
                                                                    }}>
                                                                        <option value="">Firma</option>
                                                                        {companies.map(({id, name}) => (
                                                                            <option value={id}>
                                                                                {name}
                                                                            </option>
                                                                        ))}
                                                                    </Select>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.leasingMonths`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        min={0}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input
                                                                        {...input}
                                                                        id={input.name}
                                                                        placeholder=""
                                                                        onChange={(e) => {
                                                                            input.onChange(e);
                                                                        }}
                                                                    />
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.leasingSum`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        min={0}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input
                                                                        {...input}
                                                                        id={input.name}
                                                                        placeholder=""
                                                                        onChange={(e) => {
                                                                            input.onChange(e);
                                                                        }}
                                                                    />
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.financingPeriod`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        min={0}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input
                                                                        {...input}
                                                                        id={input.name}
                                                                        placeholder=""
                                                                        onChange={(e) => {
                                                                            input.onChange(e);
                                                                        }}
                                                                    />
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.sumInsured`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        defaultValue={sumInsured}
                                                        min={0}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input
                                                                        {...input}
                                                                        id={input.name}
                                                                        placeholder=""
                                                                        onChange={(e) => {
                                                                            input.onChange(e);
                                                                        }}
                                                                    />
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.compensationLimit`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        min={0}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input
                                                                        {...input}
                                                                        id={input.name}
                                                                        placeholder=""
                                                                        onChange={(e) => {
                                                                            input.onChange(e);
                                                                        }}
                                                                    />
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.projectedTotalPremium`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        min={0}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input
                                                                        {...input}
                                                                        id={input.name}
                                                                        placeholder=""
                                                                        onChange={(e) => {
                                                                            input.onChange(e);
                                                                        }}
                                                                    />
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.projectedInstallmentPremium`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        min={0}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input
                                                                        {...input}
                                                                        id={input.name}
                                                                        placeholder=""
                                                                        disabled={!(values.gaps[index].financingPeriod > 1)}
                                                                        onChange={(e) => {
                                                                            input.onChange(e);
                                                                        }}
                                                                    />
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Files name={`${name}.files`} index={index} setFilesToForm={setFilesToForm}/>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </FieldArray>
                                </Grid>
                            </StyledWrapper>

                            {!archived && (
                                <StyledButton>Zapisz</StyledButton>
                            )}
                        </form>
                    )}

                />
            </>}
            <Comment toDoId={toDoId} toDoTab={"gap"} vehicleId={null} policyId={null} archived={false} history={false} />
        </>
    );
};
