import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {faArrowRight, faEye, faFileDownload} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {apiUrl} from "../api";
import {List} from "../../components/List";
import {StyledFontAwesomeIcon, StyledLink} from "../../components/ListComponents";
import {downloadPackage} from "../../helpers/file";

export const ClientsList = () => {
    const token = useSelector((store) => store.token);
    const readOnly = ['Partner', 'Księgowy'].includes(useSelector((store) => store.roleName));
    const [clients, setClients] = useState([]);
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState("");
    const [sortDirection, setSortDirection] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchParam, setSearchParam] = useState("");

    const tableColumns = [
        {name: 'ID', property: 'id'},
        {name: 'Imię i nazwisko/Firma', property: 'name', type: 'sort'},
        {name: 'Miejscowość', property: 'city', type: 'sort'},
        {name: 'Ilość pojazdów', property: 'vehicles_count', type: 'sort'},
        {name: 'Ilość aktywnych polis', property: 'policies_count', type: 'sort'},
        {name: 'Doprowadzający', property: 'leader', type: 'sort'},
        {name: 'Data pozyskania', property: 'acquiredDate', type: 'sort'},
    ];

    useEffect(() => {
        axios.get(`${apiUrl}/client`, {
            params: {
                page: page,
                rowsPerPage: rowsPerPage,
                sort: sort,
                sortDirection: sortDirection,
                search: searchParam
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {clients: newClients, total}}) => {
            setClients(newClients);
            setTotal(total);
        });
    }, [token, page, rowsPerPage, sort, sortDirection, searchParam])

    const callbackFunction = (pageChanged, rowsPerPageChanged, sortChanged, sortDirectionChanged, searchParamChanged) => {
        setPage(pageChanged + 1);
        setRowsPerPage(rowsPerPageChanged);
        setSort(sortChanged);
        setSortDirection(sortDirectionChanged);
        setSearchParam(searchParamChanged);
    }

    const cells = (element) => {
        return (
            <>
                <td>{element.id}</td>
                <td>{element.name ?? '-'}</td>
                <td>{element.city ?? '-'}</td>
                <td>{element.vehicleCount}</td>
                <td>{element.policiesActiveCount}</td>
                <td>{element.leader}</td>
                <td>{element.acquiredDate}</td>
            </>
        );
    }

    const actions = (element) => {
        return (
            <>
                <StyledLink onClick={()=>{
                    downloadPackage(token, 'client', element.id);
                }}>
                    <StyledFontAwesomeIcon size="2x" icon={faFileDownload} fixedWidth/>
                </StyledLink>
                <StyledLink to={`/klienci/zobacz/${element.id}`}>
                    <StyledFontAwesomeIcon size="2x" icon={faEye} fixedWidth/>
                </StyledLink>
                <StyledLink style={{display: readOnly ? "none" : "block"}} to={`/klienci/${element.id}`}>
                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                </StyledLink>
            </>
        )
    }

    return (
        <>
            <List
                tableColumns={tableColumns}
                cells={cells}
                data={clients}
                actions={actions}
                readonly={readOnly}
                initialTotal={total}
                parentCallback={callbackFunction}
                search={true}
            ></List>
        </>
    );
};
