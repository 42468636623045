import React, {useEffect, useState} from 'react';
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {InsuranceCompanyForm} from "../../components/InsuranceCompanyForm";

export const InsuranceCompaniesShow = ({match}) => {
    const [company, setCompany] = useState(null);
    const token = useSelector((store) => store.token);
    const fieldTypes = [
        {
            'key': 'text',
            'value': 'Pole tekstowe',
        },
        {
            'key': 'select',
            'value': 'Pole wyboru',
        },
        {
            'key': 'checkbox',
            'value': 'Checkbox',
        },
        {
            'key': 'number',
            'value': 'Wartość liczbowa',
        },
    ]
    useEffect(() => {
        axios.get(`${apiUrl}/companies/${match.params.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {insuranceCompany}}) => {
            setCompany(insuranceCompany);
        });
    }, [match.params.id, token]);

    return (
        <>
            <PanelContentWrapper>
                <h2>Edytuj firmę</h2>
                <InsuranceCompanyForm
                    fieldTypes={fieldTypes}
                    onSubmit={values => {}}
                    initialValues={{...company}}
                    disabled={true}
                />
            </PanelContentWrapper>
        </>
    );
};
